<template>
  <McLayoutDefault :heading="$t('headings.Reports')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('seeBilling')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card class="mb-2">
          <p>{{ $t('reports.Description') }}</p>
          <MerchantSelector allowAll permission="seeBilling" v-model="merchantId" />
          <McFormDateRange
            :config="{
              format: 'YYYY-MM-DD',
            }"
            v-model="curData.dateRange"
            :min="minDate"
            :max="maxDate"
            :label="$t('reports.ChoosePeriod')"
            :description="$t('reports.PeriodDescription')"
          />
          <!-- prettier-ignore -->
          <McFormSelect
            :options="$perms.isAdministrator() ? reportTypeOptionsAdmin : reportTypeOptionsNonAdmin"
            v-model="reportType"
            :label="$t('reports.ChooseReportType')"
            :icon="['fa', 'building']"
          />
          <div v-if="reportType === 'payments'">
            <div class="pb-2">
              <label>{{ $t('reports.GroupBy') }}</label>
              <div class="px-3 pb-2">
                <mc-form-checkbox class="m-0" v-model="curData.optionsPayments.groupBy.acquirer">{{ $t('reports.Acquirer') }}</mc-form-checkbox>
                <mc-form-checkbox class="m-0" v-model="curData.optionsPayments.groupBy.brand">{{ $t('reports.Brand') }}</mc-form-checkbox>
                <mc-form-checkbox class="m-0" v-model="curData.optionsPayments.groupBy.country">{{ $t('reports.Country') }}</mc-form-checkbox>
                <mc-form-checkbox class="m-0" v-model="curData.optionsPayments.groupBy.facilitator">{{ $t('reports.Facilitator') }}</mc-form-checkbox>
              </div>
            </div>
          </div>
          <p>{{ $t('reports.ChooseSectionsDescription') }}</p>
          <mc-button class="w-100" variant="primary" :icon="['fad', 'file-pdf']" :text="$t('reports.DownloadPdf')" target="_blank" @click="downloadReport('pdf')" />
          <mc-button class="w-100 mt-2" variant="primary" :icon="['far', 'file-excel']" :text="$t('reports.DownloadXlsx')" target="_blank" @click="downloadReport('xlsx')" />
          <mc-button class="w-100 mt-2" variant="secondary" :icon="['far', 'clock']" :text="$t('reports.ScheduleByMail')" target="_blank" @click="$bvModal.show('schedule-report-modal')" />
          <div v-if="currentSchedules.length > 0" class="mt-5">
            <p>{{ $t('reports.YourCurrentSchedules') }}</p>
            <b-card body-class="p-2" class="mb-2" v-for="(sched, key) in currentSchedules" v-bind:key="key">
              <div class="currentschedule__inner">
                <div>
                  <div v-if="$perms.hasMultipleMerchantsWithPermission('seeOrders') || $perms.isAdministrator()">
                    <small>
                      <strong>
                        <span v-if="sched.merchantId">{{ merchantInfo[sched.merchantId].niceName }}</span>
                        <span v-else>{{ $t('reports.sched.AllMerchants') }}</span>
                        <span>: </span>
                      </strong>
                    </small>
                  </div>
                  <small>{{ $t('reports.sched.' + sched.frequency) }} {{ $t('reports.sched.' + sched.type) }}</small>
                </div>
                <McButton class="ml-3" icon="trash" variant="outline-danger" size="sm" @click="deleteSchedule(sched.id)" />
              </div>
            </b-card>
          </div>
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <McSpinner v-if="loading === true" large class="my-5" />
        <div v-else-if="reportType === 'giftcards'" class="reports-overview-wrap">
          <b-alert variant="danger" class="text-center" :show="reportData.includesTestmode"><span v-html="$t('reports.NoticeTestmodeIncluded')" /></b-alert>
          <table>
            <tr>
              <td colspan="3">
                <mc-form-checkbox :description="$t('reports.IncludeDescription')" v-model="curData.optionsGiftcards.include.overview">{{ $t('reports.Overview') }}</mc-form-checkbox>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox :indeterminate="selectAll == null" class="my-0" @change="() => (selectAll = !selectAll)" :value="selectAll">&nbsp;</mc-form-checkbox>
              </td>
              <th>{{ $t('reports.Count') }}</th>
              <th>{{ $t('reports.Amount') }}</th>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.atStart">{{ $t('reports.AtStart') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.atStart.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.atStart.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.created">{{ $t('reports.Created') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.created.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.created.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.imported">{{ $t('reports.Imported') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.imported.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.imported.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.integration">{{ $t('reports.Integration') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.integration.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.integration.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.sold">{{ $t('reports.Sold') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.sold.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.sold.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.spent">{{ $t('reports.Spent') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.spent.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.spent.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.spentExpired">{{ $t('reports.OfWhichExpired') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.spentExpired.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.spentExpired.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.refunded">{{ $t('reports.Refunded') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.refunded.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.refunded.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.expired">{{ $t('reports.Expired') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.expired.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.expired.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.unexpired">{{ $t('reports.Unexpired') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.unexpired.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.unexpired.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr v-if="enddateIsFuture">
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.expires">{{ $t('reports.Expires', { date: displayEnddate }) }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.expires.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.expires.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsGiftcards.include.atEnd">{{ $t('reports.AtEnd') }}</mc-form-checkbox>
              </td>
              <td>
                <strong>{{ giftcardsReportData.atEnd.count }}</strong>
              </td>
              <td>
                <strong>{{ giftcardsReportData.atEnd.amount | niceCurrency }}</strong>
              </td>
            </tr>
          </table>
          <p class="text-center mt-3 small" v-if="enddateIsFuture" v-html="$t('reports.EndDateIsInFutureNotice')"></p>
          <p class="text-center mt-3 small" v-html="$t('reports.IfTheNumbersDontAddUpNotice')"></p>
        </div>
        <div v-else-if="reportType === 'payments'" class="reports-overview-wrap">
          <table>
            <tr>
              <td colspan="3">
                <mc-form-checkbox :description="$t('reports.PaymentsReportOverviewDescription')" v-model="curData.optionsPayments.include.overview">
                  {{ $t('reports.IncludeOverview') }}
                </mc-form-checkbox>
              </td>
            </tr>
            <tr>
              <td>
                <mc-form-checkbox v-if="paymentsReportData.length > 1" :indeterminate="selectAll == null" class="my-0" @change="() => (selectAll = !selectAll)" :value="selectAll">
                  &nbsp;
                </mc-form-checkbox>
              </td>
              <th>{{ $t('reports.Count') }}</th>
              <th>{{ $t('reports.Amount') }}</th>
            </tr>
            <tr v-for="(line, key) in paymentsReportData" v-bind:key="key">
              <td>
                <mc-form-checkbox class="my-0" v-model="curData.optionsPayments.include[line.label]">
                  {{ line.label ? (line.label === 'All' ? $t('reports.Total') : line.label) : '-' }}
                </mc-form-checkbox>
              </td>
              <td>
                <strong>{{ line.count }}</strong>
              </td>
              <td>
                <strong>{{ line.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr v-if="reportDataTotal">
              <td>
                <strong>{{ $t('reports.Total') }}</strong>
              </td>
              <td>
                <strong>{{ this.reportDataTotal.count }}</strong>
              </td>
              <td>
                <strong>{{ this.reportDataTotal.amount | niceCurrency }}</strong>
              </td>
            </tr>
          </table>
        </div>
        <div v-else-if="reportType === 'redeem'" class="reports-overview-wrap">
          <b-alert variant="danger" class="text-center" :show="reportData.includesTestmode"><span v-html="$t('reports.NoticeTestmodeIncluded')" /></b-alert>
          <table>
            <tr>
              <td colspan="3">
                <mc-form-checkbox :description="$t('reports.RedeemReportOverviewDescription')" v-model="curData.optionsRedeem.include.overview">
                  {{ $t('reports.IncludeOverview') }}
                </mc-form-checkbox>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <mc-form-checkbox :description="$t('reports.RedeemReportDetailsDescription')" v-model="curData.optionsRedeem.include.details">
                  {{ $t('reports.IncludeDetails') }}
                </mc-form-checkbox>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <th>{{ $t('reports.Count') }}</th>
              <th>{{ $t('reports.Amount') }}</th>
            </tr>
            <tr v-for="(line, key) in redeemReportData" v-bind:key="key">
              <td>{{ line.label }}</td>
              <td>
                <strong>{{ line.count }}</strong>
              </td>
              <td>
                <strong>{{ line.amount | niceCurrency }}</strong>
              </td>
            </tr>
            <tr v-if="redeemReportDataTotal">
              <td>
                <strong>{{ $t('reports.Total') }}</strong>
              </td>
              <td>
                <strong>{{ this.redeemReportDataTotal.count }}</strong>
              </td>
              <td>
                <strong>{{ this.redeemReportDataTotal.amount | niceCurrency }}</strong>
              </td>
            </tr>
          </table>
        </div>
      </b-col>
      <b-modal id="schedule-report-modal" :title="$t('reports.ScheduleReportByMail')" @ok="saveSchedule" :cancel-title="$t('general.Cancel')" :ok-title="$t('general.OK')">
        <p v-html="$t('reports.ReportWillBeSentTo', { mail: recipientMail })" />
        <p class="text-center">
          <label class="m-2"><input type="radio" value="weekly" v-model="curData.frequency" /> {{ $t('reports.ScheduleWeekly') }}</label>
          <label class="m-2"><input type="radio" value="monthly" v-model="curData.frequency" /> {{ $t('reports.ScheduleMonthly') }}</label>
        </p>
        <p v-if="curData.frequency == 'weekly'">{{ $t('reports.WeeklySpecification') }}</p>
        <p v-if="curData.frequency == 'monthly'">{{ $t('reports.MonthlySpecification') }}</p>
      </b-modal>
    </b-row>
  </McLayoutDefault>
</template>

<script>
import moment from 'moment-timezone'
import ReportsService from '@/services/ReportsService'
import { niceCurrency } from '@/helpers/Formatting'
import store from '@/setup/Store'

const defaultData = {
  dateRange: {
    from: moment().subtract(1, 'year').startOf('year').toDate(),
    to: moment().subtract(1, 'year').endOf('year').toDate(),
  },
  optionsGiftcards: {
    include: {
      atStart: true,
      created: true,
      integration: true,
      imported: true,
      sold: true,
      refunded: true,
      expired: true,
      unexpired: true,
      expires: true,
      spent: true,
      spentExpired: true,
      atEnd: true,
      overview: true,
    },
  },
  optionsPayments: {
    groupBy: {
      acquirer: true,
      brand: true,
      country: true,
      facilitator: false,
    },
    include: {
      overview: true,
    },
  },
  optionsRedeem: {
    include: {
      overview: true,
      details: true,
    },
  },
}

export default {
  data() {
    return {
      loading: true,
      merchantId: null,
      reportType: 'giftcards',
      reportTypeOptionsAdmin: [
        { label: this.$t('reports.GiftcardReport'), value: 'giftcards' },
        { label: this.$t('reports.RedeemReport'), value: 'redeem' },
        { label: this.$t('reports.PaymentsReport'), value: 'payments' },
      ],
      reportTypeOptionsNonAdmin: [
        { label: this.$t('reports.GiftcardReport'), value: 'giftcards' },
        { label: this.$t('reports.RedeemReport'), value: 'redeem' },
      ],
      reportData: {},
      curData: {
        dateRange: {
          ...defaultData.dateRange,
        },
        optionsGiftcards: {
          ...defaultData.optionsGiftcards,
        },
        optionsPayments: {
          ...defaultData.optionsPayments,
        },
        optionsRedeem: {
          ...defaultData.optionsRedeem,
        },
        frequency: 'monthly',
      },
      previewData: null,
      minDate: moment(new Date()).subtract(5, 'year').startOf('year').toDate(),
      maxDate: moment(new Date()).add(1, 'year').endOf('year').toDate(),
      currentSchedules: [],
    }
  },
  mounted() {
    this.refreshPreview()
    this.refreshSchedule()
  },
  watch: {
    merchantId() {
      this.refreshPreview()
    },
    reportType() {
      this.refreshPreview()
    },
    'curData.optionsPayments.groupBy': {
      handler() {
        this.refreshPreview()
      },
      deep: true,
    },
    'curData.dateRange': {
      handler() {
        this.refreshPreview()
      },
      deep: true,
    },
  },
  methods: {
    refreshSchedule() {
      ReportsService.getSchedules().then(data => {
        this.currentSchedules = data
      })
    },
    refreshPreview() {
      let from = moment(this.curData.dateRange.from).format('YYYY-MM-DD')
      let to = moment(this.curData.dateRange.to).format('YYYY-MM-DD')
      let previewData = {
        from,
        to,
        merchantId: this.merchantId,
        reportType: this.reportType,
        options: this.reportOptions['preview'],
      }
      if (JSON.stringify(previewData) !== JSON.stringify(this.previewData)) {
        this.loading = true
        this.previewData = previewData
        ReportsService.preview(from, to, this.reportType, this.merchantId, this.reportOptions['preview']).then(data => {
          this.reportData = data
          if (this.reportType === 'payments') {
            let newInclude = {
              overview: typeof this.curData.optionsPayments.include.overview == 'undefined' ? true : this.curData.optionsPayments.include.overview,
            }
            for (var i in this.paymentsReportData) {
              let cat = this.paymentsReportData[i].label
              newInclude[cat] = typeof this.curData.optionsPayments.include[cat] == 'undefined' ? true : this.curData.optionsPayments.include[cat]
            }
            this.curData.optionsPayments.include = newInclude
          }
          this.loading = false
        })
      }
    },
    downloadReport(format) {
      let from = moment(this.curData.dateRange.from).format('YYYY-MM-DD')
      let to = moment(this.curData.dateRange.to).format('YYYY-MM-DD')
      ReportsService.download(from, to, this.reportType, this.merchantId, this.reportOptions['export'], format).then(data => {
        window.location = data.downloadUrl
      })
    },
    saveSchedule() {
      ReportsService.schedule(this.reportType, this.merchantId, this.reportOptions['export'], this.curData.frequency).then(data => {
        this.currentSchedules = data
      })
    },
    deleteSchedule(id) {
      if (confirm(this.$t('reports.ConfirmDeleteScheduledReport'))) {
        ReportsService.deleteSchedule(id).then(data => {
          this.currentSchedules = data
        })
      }
    },
    arrayFromSelected(selection) {
      let ret = []
      for (let cat in selection) {
        if (selection[cat]) {
          ret.push(cat)
        }
      }
      return ret
    },
  },
  filters: {
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
  },
  computed: {
    recipientMail() {
      return store.state.LoginStore.user ? store.state.LoginStore.user.email : ''
    },
    merchantInfo() {
      let ret = {}
      for (var i in store.state.LoginStore.merchantInfo) {
        ret[store.state.LoginStore.merchantInfo[i].id] = store.state.LoginStore.merchantInfo[i]
      }
      return ret
    },
    reportDataTotal() {
      if (this.paymentsReportData.length < 2) {
        return false
      }
      let ret = {
        amount: 0,
        count: 0,
      }
      for (let cat in this.paymentsReportData) {
        ret.amount += this.paymentsReportData[cat].amount
        ret.count += this.paymentsReportData[cat].count
      }
      return ret
    },
    redeemReportDataTotal() {
      if (this.redeemReportData.length < 2) {
        return false
      }
      let ret = {
        amount: 0,
        count: 0,
      }
      for (let userId in this.redeemReportData) {
        ret.amount += this.redeemReportData[userId].amount
        ret.count += this.redeemReportData[userId].count
      }
      return ret
    },
    selectAll: {
      set(selected) {
        let relevantInclude = null
        switch (this.reportType) {
          case 'payments':
            relevantInclude = this.curData.optionsPayments.include
            break
          case 'redeem':
            relevantInclude = this.curData.optionsRedeem.include
            break
          default:
            relevantInclude = this.curData.optionsGiftcards.include
            break
        }
        for (let cat in relevantInclude) {
          if (cat != 'overview') {
            relevantInclude[cat] = selected
          }
        }
      },
      get() {
        let allOn = true
        let allOff = true
        let relevantInclude = null
        switch (this.reportType) {
          case 'payments':
            relevantInclude = this.curData.optionsPayments.include
            break
          case 'redeem':
            relevantInclude = this.curData.optionsRedeem.include
            break
          default:
            relevantInclude = this.curData.optionsGiftcards.include
            break
        }
        for (let cat in relevantInclude) {
          if (cat == 'overview') {
            continue
          }
          if (relevantInclude[cat]) {
            allOff = false
          } else {
            allOn = false
          }
        }
        if (allOn) {
          return true
        } else if (allOff) {
          return false
        }
        return null
      },
    },
    reportOptions() {
      let reportOptions = {
        preview: {},
        export: {},
      }
      if (this.reportType === 'giftcards') {
        reportOptions['export'].include = this.arrayFromSelected(this.curData.optionsGiftcards.include)
      } else if (this.reportType === 'redeem') {
        reportOptions['export'].include = this.arrayFromSelected(this.curData.optionsRedeem.include)
      } else if (this.reportType === 'payments') {
        reportOptions['preview'].groupBy = this.arrayFromSelected(this.curData.optionsPayments.groupBy)
        reportOptions['export'].groupBy = this.arrayFromSelected(this.curData.optionsPayments.groupBy)
        reportOptions['export'].include = this.arrayFromSelected(this.curData.optionsPayments.include)
      }
      return reportOptions
    },
    giftcardsReportData() {
      return this.reportData.sections
    },
    paymentsReportData() {
      let ret = []
      for (var label in this.reportData) {
        ret.push({ label: label, count: this.reportData[label].count, amount: this.reportData[label].amount })
      }
      return ret
    },
    redeemReportData() {
      let ret = []
      for (var idx in this.reportData.users) {
        ret.push({ label: this.reportData.users[idx].username, count: this.reportData.users[idx].count, amount: this.reportData.users[idx].amount })
      }
      return ret
    },
    displayEnddate() {
      return moment(this.curData.dateRange.to).format('ll')
    },
    enddateIsFuture() {
      return moment(this.curData.dateRange.to).endOf('day') > moment()
    },
  },
}
</script>

<style lang="scss">
.reports-overview-wrap {
  table {
    margin: 0 auto;
    width: 100%;
  }
  td,
  th {
    padding: 0.3em 1em;
    border-bottom: 1px solid #ddd;
    text-align: right;
    &:first-child {
      text-align: left;
    }
    white-space: nowrap;
    @media screen and (max-width: 500px) {
      padding: 0.3em;
    }
  }
  tr:last-child td {
    border: none;
  }
}
.currentschedule__inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
</style>
