<template>
  <div>
    <!-- eslint-disable-next-line -->
    <mc-form-input
      name="certificateNumber"
      type="text"
      v-model="code"
      :label="$t('general.EnterCodeOrScanQrUsingButtonOnTheRight')"
      :placeholder="$t('general.StartTypingCodeOrScanQR')"
      @input="handleSearch"
    >
      <template #append>
        <mc-button @click="startQrScanner" :text="$t('general.QR')" variant="info" :icon="['far', 'qrcode']"></mc-button>
      </template>
    </mc-form-input>
    <span v-if="codeIsValid && codeCount == 0">{{ $t('general.NoResults') }}</span>
    <span v-else-if="codeCount > 5">{{ $t('general.TooManyResultsKeepTyping') }}</span>
    <ul class="flat_list m-0" v-else-if="codeIsValid && codes">
      <li v-for="(certificate, code) in codes" v-bind:key="code">
        <Certificate @redeemed="actualSearch" @extended="actualSearch" @offlineFinalized="actualSearch" :certificate="certificate" :forceDetailsOut="Object.keys(codes).length == 1" />
      </li>
    </ul>
    <div class="text-center" v-else>
      <createOrderButtonAndForm class="mt-5 mb-2" />
    </div>
    <QrScannerNativePlusJsQr fullscreen ref="scanner" @code="handleQr" :error="qrError" />
  </div>
</template>

<script>
import CodesService from '@/services/CodesService'
import { FormatError } from '@/helpers/ErrorFormatting'

export default {
  data() {
    return {
      code: '',
      codes: [],
      qrError: '',
      storedErrors: {},
    }
  },
  computed: {
    codeCount() {
      //console.log(Object.keys(this.codes).length)
      return Object.keys(this.codes).length
    },
    codeIsValid() {
      return this.code.trim().length > 0
    },
  },
  created() {
    this.$_filtertimeout = null
  },
  beforeDestroy() {
    clearTimeout(this.$_filtertimeout)
  },
  methods: {
    startQrScanner() {
      this.qrError = ''
      this.storedErrors = {}
      this.$refs['scanner'].start()
    },
    handleQr(data) {
      if (!data) {
        this.qrError = ''
        return
      }
      let matches = data.match(/(?:d\.gkort\.dk|qr\.danskegavekort\.dk|panel\.danskegavekort\.dk\/qr|192\.168\.0\.[0-9]+.*)\/(c.{8,})$/)
      if (!matches) {
        this.qrError = this.$t('qrReader.NotValidCertificateQR')
        return
      }
      const token = matches[1]
      if (this.storedErrors[token]) {
        this.qrError = FormatError(this.storedErrors[token])
        return
      }
      this.qrError = this.$t('general.OneMoment')
      CodesService.searchQR(token).then(
        data => {
          this.codes = [data.code]
          this.code = data.code.code
          this.$refs['scanner'].stop()
          return Promise.resolve(data)
        },
        error => {
          this.qrError = FormatError(error)
          this.storedErrors[token] = error
          return Promise.reject(error)
        },
      )
    },
    actualSearch() {
      if (!this.codeIsValid) {
        return
      }
      CodesService.search(this.code.trim()).then(
        data => {
          this.codes = data.codes
          return Promise.resolve(data)
        },
        error => {
          alert(FormatError(error))
          return Promise.reject(error)
        },
      )
    },
    handleSearch() {
      clearTimeout(this.$_filtertimeout)
      this.$_filtertimeout = setTimeout(this.actualSearch, 500)
    },
  },
}
</script>
