<template>
  <McLayoutDefault>
    <h1>Testpage...</h1>

    <mc-form-multiinput
      name="email"
      :label="$t('general.YourEmail')"
      :placeholder="$t('general.EnterEmail')"
      key="profileform.email"
      icon="at"
      :description="$t('general.YourEmailWillBeConfirmed')"
      v-model="multivalue"
      isValidating
      required
    />
    <pre>{{ multivalue }}</pre>

    <mc-form-multiinput
      name="phone"
      :label="$t('general.YourPhone')"
      :placeholder="$t('general.EnterPhone')"
      key="profileform.phone"
      icon="phone"
      :description="$t('general.YourEmailWillBeConfirmed')"
      v-model="multiphone"
      isValidating
      type="telephone"
      required
    />
    <pre>{{ multiphone }}</pre>
  </McLayoutDefault>
</template>

<script>
export default {
  data() {
    return {
      multivalue: 'mikkel@softwork.dk,thomas@softwork.dk,a@b.dk',
      multiphone: '53271965,53565904,123',
    }
  },
}
</script>
