import { authorizedApiRequest, queryParameters } from '@/setup/ApiService'

export default {
  preview: function (dateStart, dateEnd, reportType, merchantId, reportOptions) {
    return authorizedApiRequest(
      (merchantId ? `/reports/${merchantId}/` : `/reports/`) +
        reportType +
        '?' +
        queryParameters({
          dateStart,
          dateEnd,
        }),
      'POST',
      {
        options: reportOptions,
      },
    )
  },
  download: function (dateStart, dateEnd, reportType, merchantId, reportOptions, format) {
    return authorizedApiRequest(
      (merchantId ? `/reports/${merchantId}/` : `/reports/`) +
        reportType +
        '?' +
        queryParameters({
          dateStart,
          dateEnd,
          format,
        }),
      'POST',
      {
        options: reportOptions,
        export: 'pdf',
      },
    )
  },
  schedule: function (reportType, merchantId, reportOptions, frequency) {
    return authorizedApiRequest(
      '/reports/schedules/' + (merchantId ? merchantId + '/' : '') + reportType, //
      'POST',
      {
        options: reportOptions,
        frequency: frequency,
      },
    )
  },
  getSchedules: function () {
    return authorizedApiRequest(
      '/reports/schedules', //
      'GET',
    )
  },
  deleteSchedule: function (id) {
    return authorizedApiRequest(
      '/reports/schedules/' + id, //
      'DELETE',
    )
  },
}
