<template>
  <div ref="outer" :class="'valexp-wrap valexp-' + element.layout">
    <span ref="code_value" class="code-value">
      <label ref="code_value_label">{{ valueLabel }}</label>
      <span>&nbsp;</span>
      <label ref="code_value_value">{{ valueValue }}</label>
    </span>
    <span ref="code_code" class="code-code">
      <label>{{ codeLabel }}</label>
      <span>&nbsp;</span>
      <label>{{ codeValue }}</label>
    </span>
    <span ref="code_expiry" class="code-expiry">
      <label>{{ expiryLabel }}</label>
      <span>&nbsp;</span>
      <label>{{ expiryValue }}</label>
    </span>
    <span ref="code_divider" class="code-divider" />
    <span ref="code_credit" class="code-credit">{{ creditLabel }}</span>
  </div>
</template>

<script>
export default {
  props: {
    layout: String,
    element: Object,
    pixelsPerMm: Number,
    valueLabel: String,
    valueValue: String,
    codeLabel: String,
    codeValue: String,
    expiryLabel: String,
    expiryValue: String,
    creditLabel: String,
  },
  updated() {
    this.redrawAll()
  },
  mounted() {
    this.reOb = new ResizeObserver((e) => {
      this.redrawAll()
    })
    this.reOb.observe(this.$el)
    this.redrawAllAndRepeat(20)
  },
  unmount() {
    this.reOb.disconnect()
  },
  watch: {
    'element.font'() {
      this.redrawAllAndRepeat(20)
    },
    'element.fontWeight'() {
      this.redrawAllAndRepeat(20)
    },
    'element.fontWeightBold'() {
      this.redrawAllAndRepeat(20)
    },
    'element.fontSpacing'() {
      this.redrawAllAndRepeat(20)
    },
    element: {
      deep: true,
      handler: function () {
        this.redrawAll()
      },
    },
  },
  methods: {
    redrawAllAndRepeat(repeats) {
      this.redrawAll()
      if (repeats > 0) {
        setTimeout(() => {
          this.redrawAllAndRepeat(repeats - 1)
        }, 50)
      }
    },
    redrawAll() {
      if (!this.$refs.outer) {
        return
      }
      this.$refs.code_value.removeAttribute('style')
      this.$refs.code_code.removeAttribute('style')
      this.$refs.code_expiry.removeAttribute('style')
      this.$refs.code_code.removeAttribute('style')
      this.$refs.code_value_label.removeAttribute('style')
      this.$refs.code_value_value.removeAttribute('style')


      if (this.layout == 'layout-1') {
        let fontSizeValue = this.textFillSize(this.$refs.code_value, this.$refs.outer.clientWidth)
        let fontSizeDetails = 9999

        if (this.element.layoutOptions.includes('codeAndExpiry')) {
          let fontSizeCode = this.textFillSize(this.$refs.code_code, this.$refs.outer.clientWidth / 2)
          let fontSizeExpiry = this.textFillSize(this.$refs.code_code, this.$refs.outer.clientWidth / 2)
          fontSizeDetails = Math.min(fontSizeCode, fontSizeExpiry, fontSizeDetails)
        }
        if (this.element.layoutOptions.includes('credit')) {
          let fontSizeCredit = this.textFillSize(this.$refs.code_credit, this.$refs.outer.clientWidth)
          fontSizeDetails = Math.min(fontSizeCredit, fontSizeDetails)
        }
        this.$refs.code_value.style.fontSize = fontSizeValue + 'px'
        this.$refs.code_code.style.fontSize = fontSizeDetails + 'px'
        this.$refs.code_expiry.style.fontSize = fontSizeDetails + 'px'
        this.$refs.code_credit.style.fontSize = fontSizeDetails + 'px'

        let separatorHeight = this.element.separatorWidth * this.pixelsPerMm

        let leftOver = this.$refs.outer.clientHeight
        let spaces = -1

        if (this.element.layoutOptions.includes('value')) {
          leftOver -= fontSizeValue
          spaces++
        }
        if (this.element.layoutOptions.includes('codeAndExpiry')) {
          leftOver -= fontSizeDetails
          spaces++
        }
        if (this.element.layoutOptions.includes('credit')) {
          leftOver -= fontSizeDetails
          spaces++
        }

        //If we have less than two elements, don't show separator
        const forceHideSep = spaces <= 0
        if (this.element.layoutOptions.includes('separator') && !forceHideSep) {
          leftOver -= separatorHeight
          spaces++
        }

        let curTop = 0
        const oneSpace = spaces > 0 ? leftOver / spaces : 0
        if (spaces == 0) {
          curTop = leftOver / 2
        }

        if (this.element.layoutOptions.includes('value')) {
          this.$refs.code_value.style.opacity = 1
          this.$refs.code_value.style.top = curTop + 'px'
          curTop += fontSizeValue + oneSpace
        } else {
          this.$refs.code_value.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('separator') && !forceHideSep && !this.element.layoutOptions.includes('credit')) {
          this.$refs.code_divider.style.top = curTop + 'px'
          curTop += separatorHeight + oneSpace
        }

        if (this.element.layoutOptions.includes('codeAndExpiry')) {
          this.$refs.code_code.style.opacity = 1
          this.$refs.code_code.style.top = curTop + 'px'
          this.$refs.code_expiry.style.opacity = 1
          this.$refs.code_expiry.style.top = curTop + 'px'
          this.$refs.code_expiry.style.right = '0px'
          curTop += fontSizeDetails + oneSpace
        } else {
          this.$refs.code_code.style.opacity = 0
          this.$refs.code_expiry.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('separator') && !forceHideSep && this.element.layoutOptions.includes('credit')) {
          this.$refs.code_divider.style.top = curTop + 'px'
          curTop += separatorHeight + oneSpace
        }

        if (this.element.layoutOptions.includes('separator') && !forceHideSep) {
          this.$refs.code_divider.style.opacity = 1
          this.$refs.code_divider.style.height = separatorHeight + 'px'
          this.$refs.code_divider.style.width = '100%'
        } else {
          this.$refs.code_divider.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('credit')) {
          this.$refs.code_credit.style.opacity = 1
          this.$refs.code_credit.style.width = '100%'
          this.$refs.code_credit.style.top = curTop + 'px'
        } else {
          this.$refs.code_credit.style.opacity = 0
        }
      } else if (this.layout == 'layout-2') {
        let fontSizeValue
        let fontSizeCode
        let fontSizeExpiry
        let fontSizeCredit
        let fontSizeDetails
        if (this.element.fontSizes == 'multiple') {
          fontSizeValue = this.textFillSize(this.$refs.code_value, this.$refs.outer.clientWidth)
          fontSizeCode = this.textFillSize(this.$refs.code_code, this.$refs.outer.clientWidth * .8)
          fontSizeExpiry = this.textFillSize(this.$refs.code_expiry, this.$refs.outer.clientWidth * .8)
          fontSizeCredit = this.textFillSize(this.$refs.code_credit, this.$refs.outer.clientWidth)
          fontSizeDetails = Math.min(fontSizeCode, fontSizeExpiry)
        } else {
          let fontSizeAll = 999999
          if (this.element.layoutOptions.includes('value')) {
            let thisFontSize = this.textFillSize(this.$refs.code_value, this.$refs.outer.clientWidth * .8)
            fontSizeAll = Math.min(thisFontSize, fontSizeAll)
          }
          if (this.element.layoutOptions.includes('code')) {
            let thisFontSize = this.textFillSize(this.$refs.code_code, this.$refs.outer.clientWidth * .8)
            fontSizeAll = Math.min(thisFontSize, fontSizeAll)
          }
          if (this.element.layoutOptions.includes('expiry')) {
            let thisFontSize = this.textFillSize(this.$refs.code_expiry, this.$refs.outer.clientWidth * .8)
            fontSizeAll = Math.min(thisFontSize, fontSizeAll)
          }
          if (this.element.layoutOptions.includes('credit')) {
            let thisFontSize = this.textFillSize(this.$refs.code_credit, this.$refs.outer.clientWidth)
            fontSizeAll = Math.min(thisFontSize, fontSizeAll)
          }
          fontSizeValue = fontSizeAll
          fontSizeDetails = fontSizeAll
          fontSizeCredit = fontSizeAll
        }

        this.$refs.code_value.style.fontSize = fontSizeValue + 'px'
        this.$refs.code_code.style.fontSize = fontSizeDetails + 'px'
        this.$refs.code_expiry.style.fontSize = fontSizeDetails + 'px'
        this.$refs.code_credit.style.fontSize = fontSizeCredit + 'px'

        let separatorHeight = this.element.separatorWidth * this.pixelsPerMm

        let leftOver = this.$refs.outer.clientHeight
        let spaces = -1

        if (this.element.layoutOptions.includes('value')) {
          leftOver -= fontSizeValue
          spaces++
        }
        if (this.element.layoutOptions.includes('code')) {
          leftOver -= fontSizeDetails
          spaces++
        }
        if (this.element.layoutOptions.includes('expiry')) {
          leftOver -= fontSizeDetails
          spaces++
        }

        //If we have NO other elements, don't show separator
        const forceHideSep = spaces == -1 || !this.element.layoutOptions.includes('credit')
        if (this.element.layoutOptions.includes('separator') && !forceHideSep) {
          leftOver -= separatorHeight
          spaces++
        }
        if (this.element.layoutOptions.includes('credit')) {
          leftOver -= fontSizeCredit
          spaces++
        }

        let curTop = 0
        const oneSpace = spaces > 0 ? leftOver / spaces : 0
        if (spaces == 0) {
          curTop = leftOver / 2
        }

        if (this.element.layoutOptions.includes('value')) {
          this.$refs.code_value.style.opacity = 1
          this.$refs.code_value.style.top = curTop + 'px'
          curTop += fontSizeValue + oneSpace
        } else {
          this.$refs.code_value.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('code')) {
          this.$refs.code_code.style.opacity = 1
          this.$refs.code_code.style.top = curTop + 'px'
          curTop += fontSizeDetails + oneSpace
        } else {
          this.$refs.code_code.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('expiry')) {
          this.$refs.code_expiry.style.opacity = 1
          this.$refs.code_expiry.style.top = curTop + 'px'
          curTop += fontSizeDetails + oneSpace
        } else {
          this.$refs.code_expiry.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('separator') && !forceHideSep) {
          this.$refs.code_divider.style.opacity = 1
          this.$refs.code_divider.style.top = curTop + 'px'
          this.$refs.code_divider.style.height = separatorHeight + 'px'
          this.$refs.code_divider.style.width = '100%'
          curTop += separatorHeight + oneSpace
        } else {
          this.$refs.code_divider.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('credit')) {
          this.$refs.code_credit.style.opacity = 1
          this.$refs.code_credit.style.top = curTop + 'px'
        } else {
          this.$refs.code_credit.style.opacity = 0
        }

        if (this.element.textAlign === 'justify') {
          this.$refs.code_value.style.width = '100%'
          this.$refs.code_value.style.display = 'flex'
          this.$refs.code_value.style.justifyContent = 'space-between'
          this.$refs.code_expiry.style.width = '100%'
          this.$refs.code_expiry.style.display = 'flex'
          this.$refs.code_expiry.style.justifyContent = 'space-between'
          this.$refs.code_code.style.width = '100%'
          this.$refs.code_code.style.display = 'flex'
          this.$refs.code_code.style.justifyContent = 'space-between'
        } else {
          this.$refs.code_value.style.width = '100%'
          this.$refs.code_value.style.textAlign = this.element.textAlign
          this.$refs.code_code.style.width = '100%'
          this.$refs.code_code.style.textAlign = this.element.textAlign
          this.$refs.code_expiry.style.width = '100%'
          this.$refs.code_expiry.style.textAlign = this.element.textAlign
          this.$refs.code_credit.style.width = '100%'
        }
      } else if (this.layout == 'layout-3') {
        let fontSizeValue = this.textFillSize(this.$refs.code_value, this.$refs.outer.clientWidth * .5)

        let fontSizeCode = this.textFillSize(this.$refs.code_code, this.$refs.outer.clientWidth * .4)
        let fontSizeExpiry = this.textFillSize(this.$refs.code_code, this.$refs.outer.clientWidth * .4)
        let fontSizeDetails = Math.min(fontSizeCode, fontSizeExpiry)

        let fontSizeCredit = this.textFillSize(this.$refs.code_credit, this.$refs.outer.clientWidth)

        this.$refs.code_value.style.fontSize = fontSizeValue + 'px'
        this.$refs.code_code.style.fontSize = fontSizeDetails + 'px'
        this.$refs.code_expiry.style.fontSize = fontSizeDetails + 'px'
        this.$refs.code_credit.style.fontSize = fontSizeCredit + 'px'

        let separatorHeight = this.element.separatorWidth * this.pixelsPerMm

        let leftOver = this.$refs.outer.clientHeight
        let spaces = -1

        leftOver -= fontSizeValue * .5
        spaces++

        leftOver -= fontSizeValue
        spaces++

        if (this.element.layoutOptions.includes('credit')) {
          leftOver -= fontSizeCredit
          spaces++
        }

        //If we have less than two elements, don't show separator
        const forceHideSep = spaces <= 0
        if (this.element.layoutOptions.includes('separator') && !forceHideSep) {
          leftOver -= separatorHeight
          spaces++
        }

        let curTop = 0
        const oneSpace = spaces > 0 ? leftOver / spaces : 0
        if (spaces == 0) {
          curTop = leftOver / 2
        }

        this.$refs.code_value.style.opacity = 1
        this.$refs.code_value.style.top = curTop + 'px'
        this.$refs.code_value.style.width = '50%'
        this.$refs.code_value.style.height = fontSizeValue * 1.5 + oneSpace + 'px'
        this.$refs.code_value.style.right = '0px'
        this.$refs.code_value_value.style.top = oneSpace + fontSizeValue * .5 + 'px'
        this.$refs.code_value_value.style.position = 'absolute'

        this.$refs.code_code.style.opacity = 1
        this.$refs.code_code.style.top = curTop + fontSizeValue / 4 - fontSizeDetails / 2 + 'px'
        this.$refs.code_code.style.width = '40%'
        curTop += fontSizeValue / 2 + oneSpace

        this.$refs.code_expiry.style.opacity = 1
        this.$refs.code_expiry.style.top = curTop + fontSizeValue / 2 - fontSizeDetails / 2 + 'px'
        this.$refs.code_expiry.style.width = '40%'
        curTop += fontSizeValue + oneSpace

        if (this.element.layoutOptions.includes('separator') && !forceHideSep) {
          this.$refs.code_divider.style.top = curTop + 'px'
          curTop += separatorHeight + oneSpace
          this.$refs.code_divider.style.opacity = 1
          this.$refs.code_divider.style.height = separatorHeight + 'px'
          this.$refs.code_divider.style.width = '100%'
        } else {
          this.$refs.code_divider.style.opacity = 0
        }

        if (this.element.layoutOptions.includes('credit')) {
          this.$refs.code_credit.style.opacity = 1
          this.$refs.code_credit.style.width = '100%'
          this.$refs.code_credit.style.top = curTop + 'px'
        } else {
          this.$refs.code_credit.style.opacity = 0
        }
      } else if (this.layout == 'layout-4' || this.layout === 'layout-5') {
        let multiplier = this.layout === 'layout-5' ? 1.5 : 1
        let fontSizeValue = Math.min(this.$refs.outer.clientHeight / multiplier, this.textFillSize(this.$refs.code_value, this.$refs.outer.clientWidth * .3))
        let fontSizeCode = Math.min(this.$refs.outer.clientHeight / multiplier, this.textFillSize(this.$refs.code_code, this.$refs.outer.clientWidth * .3))
        let fontSizeExpiry = Math.min(this.$refs.outer.clientHeight / multiplier, this.textFillSize(this.$refs.code_expiry, this.$refs.outer.clientWidth * .3))
        fontSizeValue = Math.min(fontSizeValue, fontSizeCode, fontSizeExpiry)
        if (this.element.fontSizes == 'one') {
          fontSizeCode = fontSizeValue
          fontSizeExpiry = fontSizeValue
        } else {
          fontSizeCode = fontSizeValue * .7
          fontSizeExpiry = fontSizeValue * .7
        }

        this.$refs.code_value.style.top = this.$refs.outer.clientHeight / 2 - fontSizeValue * multiplier / 2 + 'px'
        this.$refs.code_value.style.fontSize = fontSizeValue + 'px'
        this.$refs.code_code.style.top = this.$refs.outer.clientHeight / 2 - fontSizeCode * multiplier / 2 + 'px'
        this.$refs.code_code.style.fontSize = fontSizeCode + 'px'
        this.$refs.code_expiry.style.top = this.$refs.outer.clientHeight / 2 - fontSizeExpiry * multiplier / 2 + 'px'
        this.$refs.code_expiry.style.fontSize = fontSizeExpiry + 'px'

        this.$refs.code_value.style.left = '50%'
        this.$refs.code_value.style.transform = 'translateX(-50%)'
        this.$refs.code_value.style.textAlign = 'center'

        this.$refs.code_code.style.left = '0'
        this.$refs.code_code.style.width = '30%'
        this.$refs.code_code.style.textAlign = 'left'
        this.$refs.code_expiry.style.right = '0'
        this.$refs.code_expiry.style.width = '30%'
        this.$refs.code_expiry.style.textAlign = 'right'

        this.$refs.code_divider.style.opacity = 0
        this.$refs.code_credit.style.opacity = 0

        if (this.layout === 'layout-5') {
          if (!this.element.layoutOptions.includes('codeAndExpiry')) {
            this.$refs.code_code.style.opacity = 0
            this.$refs.code_expiry.style.opacity = 0
          }
          if (!this.element.layoutOptions.includes('value')) {
            this.$refs.code_value.style.opacity = 0
          }
        }
      }
    },
    textFillSize(el, targetWidth) {
      let clone = el.cloneNode(true)
      el.after(clone)
      clone.style.fontSize = '200px'
      clone.style.overflowX = 'scroll'
      clone.style.width = '100px'
      let rel = clone.scrollWidth / targetWidth
      clone.remove()
      return 200 / rel
    },
  },
}
</script>
<style lang="scss" scoped>
.valexp-wrap {
  text-align: left;
  white-space: nowrap;
  line-height: 1;
  > span {
    position: absolute;
  }
  .code-divider {
    background: v-bind('element.forecolor');
  }
  .code-credit {
    text-align: center !important;
    font-style: italic;
  }
  &.valexp-layout-3 {
    .code-value label {
      text-align: center;
      display: block;
      &:first-child {
        font-size: .5em;
      }
    }
  }
  &.valexp-layout-5 {
    label {
      text-align: center;
      display: block;
      &:first-child {
        font-size: .5em;
      }
    }
    span {
      display: inline-block;
    }
    span span {
      display: none;
    }
  }
}
</style>
