<template>
  <McLayoutDefault :heading="pageHeading">
    <b-row class="mb-3" v-if="$perms.hasMerchantsWithPermission('seeOrders') || $perms.isAdministrator()">
      <b-col class="d-flex mb-3" lg="6" cols="12">
        <b-card class="flex-fill" :title="$t('general.QuickSearchCode')">
          <CertificateSearch />
        </b-card>
      </b-col>
      <b-col class="d-flex mb-3" lg="6" cols="12">
        <b-card class="flex-fill" :title="$t('billing.SalesOverview')">
          <SalesOverview />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-3" v-else-if="$perms.hasMerchantsWithPermission('markCodes')">
      <b-col class="d-flex mb-3" lg="6" offset-lg="3" md="8" offset-md="2" cols="12">
        <div class="text-center w-100">
          <h2>{{ $t('general.QuickSearchCode') }}</h2>
          <CertificateSearch />
        </div>
      </b-col>
    </b-row>

    <chartOrders class="my-3" :withJoke="false" />

    <div v-if="$perms.isQrUser()">
      <p class="text-center" v-if="Object.keys(LoginStore.merchantInfo).length == 0">{{ $t('general.YoureLoggedInButHaveNoPerms') }}</p>
    </div>
    <div v-else-if="$perms.isAdministrator() || Object.keys(LoginStore.merchantInfo).length > 0" class="text-center">
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/orders"
        :icon="['fad', 'file-invoice']"
        :text="$t('headings.Orders')"
        v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('seeOrders')"
      />
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/billing"
        :icon="['fad', 'file-invoice-dollar']"
        :text="$t('headings.Billing')"
        v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('seeBilling')"
      />
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/reports"
        :icon="['fad', 'file-pdf']"
        :text="$t('headings.Reports')"
        v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('seeBilling')"
      />
      <!-- eslint-disable-next-line -->
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/users"
        :icon="['fad', 'users']"
        :text="$t('headings.Users')"
        v-if="$perms.isAdministrator() || $perms.isOwnerOfMerchants()"
      />
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/gift-cards"
        :icon="['fad', 'file-certificate']"
        :text="$t('headings.GiftCardTypes')"
        v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('manageCertificates')"
      />
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/integration"
        :icon="['fad', 'cloud-upload']"
        :text="$t('headings.Integration')"
        v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('accessIntegration')"
      />
      <!-- eslint-disable-next-line -->
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/merchants"
        icon="building"
        v-if="$perms.isAdministrator() || $perms.isOwnerOfMerchants() || $perms.hasMerchantsWithPermission('handleCompany')"
        :text="$tc('headings.Merchants', $perms.hasSeveralMerchants() ? 2 : 1)"
      />
      <!-- eslint-disable-next-line -->
      <McButton
        square
        class="m-2"
        variant="primary"
        to="/profile"
        :icon="['fad', 'id-card']"
        v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('seeOrders')"
        :text="$t('headings.Profile')"
      />
    </div>
    <b-row v-else-if="Object.keys(LoginStore.merchantInfo).length == 0">
      <b-col md="4" cols="12">
        <b-card :title="$t('homepage.SideBarGreeting', { name: LoginStore.user.name })" class="mb-2">
          <p>{{ $t('homepage.NoMerchants2') }}</p>
          <p>{{ $t('homepage.NoMerchants3') }}</p>
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <MerchantForm />
      </b-col>
    </b-row>
  </McLayoutDefault>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
    }),
    pageHeading() {
      if (this.$perms.isQrUser()) {
        return ''
      }
      return this.$perms.isAdministrator() ? this.$t('homepage.AdministratorPanel') : this.$t('homepage.PartnerPanel')
    },
  },
}
</script>

<style lang="scss">
.merchant_logo_home {
  width: 100%;
  max-height: 40px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
  img {
    opacity: 0;
    width: 100%;
  }
}
</style>
