<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'save' : 'plus'"
    :title="title"
    :submittext="isEditing ? $t('general.Save') : $t('general.Create')"
  >
    <mc-form-input
      name="headline"
      type="text"
      v-model="curData.headline"
      v-validate="'required'"
      :data-vv-as="$t('deliveryMethods.Headline')"
      :label="$t('deliveryMethods.Headline')"
      :placeholder="$t('deliveryMethods.EnterHeadline')"
      key="deliverymethodform.headline"
      :errorHint="errors.first('headline')"
      :state="!errors.has('headline')"
      :icon="['far', 'heading']"
      :description="$t('deliveryMethods.HeadlineDescription')"
    />

    <mc-form-input
      :icon="['fad', 'file-contract']"
      name="description"
      type="wysiwyg"
      buttons="most"
      :data-vv-as="$t('deliveryMethods.Description')"
      :label="$t('deliveryMethods.Description')"
      v-model="curData.description"
      :errorHint="errors.first('description')"
      :state="!errors.has('description')"
      key="deliverymethodform.description"
      :placeholder="$t('deliveryMethods.EnterDescription')"
      :description="$t('deliveryMethods.DescriptionDescription')"
    />

    <mcFormDualInputs
      :errorHint="errors.first('pricePerItem') || errors.first('pricePerOrder')"
      :state="!errors.has('pricePerItem') && !errors.has('pricePerOrder')"
      :description="$t('deliveryMethods.PriceDescription')"
    >
      <template v-slot:input1>
        <mc-form-input
          :icon="['fad', 'money-bill-wave']"
          name="pricePerItem"
          type="number"
          :min="0"
          max="1000"
          step="1"
          v-validate="'required|decimal:2|min_value:0'"
          :data-vv-as="$t('deliveryMethods.PricePerItem')"
          :label="$t('deliveryMethods.PricePerItem')"
          v-model="curData.pricePerItem"
          :placeholder="$t('deliveryMethods.EnterPrice')"
          :state="!errors.has('pricePerItem')"
          :disabled="fullCommission && !$perms.isAdministrator()"
          key="deliverymethodform.pricePerItem"
          :unit="$t('general.DKK')"
        />
      </template>
      <template v-slot:input2>
        <mc-form-input
          :icon="['fad', 'money-bill-wave']"
          name="pricePerOrder"
          type="number"
          :min="0"
          max="1000"
          step="1"
          v-validate="'required|decimal:2|min_value:0'"
          :data-vv-as="$t('deliveryMethods.PricePerOrder')"
          :label="$t('deliveryMethods.PricePerOrder')"
          v-model="curData.pricePerOrder"
          :placeholder="$t('deliveryMethods.EnterPrice')"
          :state="!errors.has('pricePerOrder')"
          :disabled="fullCommission && !$perms.isAdministrator()"
          key="deliverymethodform.pricePerOrder"
          :unit="$t('general.DKK')"
        />
      </template>
    </mcFormDualInputs>

    <div v-if="curData.pricePerItem || curData.pricePerOrder">
      <mc-form-checkbox v-if="$perms.isAdministrator()" v-model="fullCommission">{{ $t('deliveryMethods.PaidToDG') }}</mc-form-checkbox>

      <mc-form-select
        v-if="!fullCommission && userList.length > 1"
        name="userId"
        v-model="curData.userId"
        :data-vv-as="$t('deliveryMethods.UserToCredit')"
        :label="$t('deliveryMethods.UserToCredit')"
        :placeholder="$t('deliveryMethods.ChooseUser')"
        :description="$t('deliveryMethods.UserToCreditDescription')"
        key="deliverymethodform.userId"
        :errorHint="errors.first('userId')"
        :state="!errors.has('userId')"
        :options="userList"
        :icon="['fad', 'user-cog']"
      />
    </div>

    <!-- eslint-disable-next-line -->
    <McFormImage
      name="file"
      :label="$t('deliveryMethods.Image')"
      :placeholder="$t('deliveryMethods.PickImage')"
      icon="image"
      v-model="curData.image"
      :description="$t('deliveryMethods.ImageDescription')"
    />

    <mc-form-checkbox :label="$t('deliveryMethods.RequireAddress')" v-model="curData.requireAddress" :description="$t('deliveryMethods.RequireAddressDescription')">{{ $t('deliveryMethods.AddressMandatoryWithDeliveryMethod') }}</mc-form-checkbox>

    <mc-form-checkbox v-if="curData.requireAddress" :label="$t('deliveryMethods.RequireDeliveryShop')" v-model="curData.requireDeliveryShop" :description="$t('deliveryMethods.RequireDeliveryShopDescription')">{{ $t('deliveryMethods.DeliveryShopMandatoryWithDeliveryMethod') }}</mc-form-checkbox>

    <mc-form-checkbox v-if="false" v-model="curData.attachCertificate" :description="$t('deliveryMethods.AttachCertificateDescription')">{{ $t('deliveryMethods.AttachCertificate') }}</mc-form-checkbox>

    <mc-form-checkbox v-model="curData.useOfflineGiftcard" :description="$t('deliveryMethods.UseOfflineGiftcardDescription')">{{ $t('deliveryMethods.UseOfflineGiftcard') }}</mc-form-checkbox>

    <mc-form-checkbox v-model="setEmailContent">{{ $t('deliveryMethods.SetEmailContent') }}</mc-form-checkbox>

    <TransitionToAuto v-if="setEmailContent">
      <b-card>
        <p v-html="$t('deliveryMethods.YouCanUseTheseTags')" />
        <mc-form-input
          name="emailSubject"
          type="text"
          v-model="curData.emailSubject"
          v-validate="'required'"
          :data-vv-as="$t('deliveryMethods.EmailSubject')"
          :label="$t('deliveryMethods.EmailSubject')"
          :placeholder="$t('deliveryMethods.EnterEmailSubject')"
          key="deliverimethodsform.emailSubject"
          :errorHint="errors.first('emailSubject')"
          :state="!errors.has('emailSubject')"
          :disabled="fullCommission && !$perms.isAdministrator()"
          :icon="['far', 'heading']"
        />

        <mc-form-input
          :icon="['fad', 'file-contract']"
          name="emailBody"
          type="wysiwyg"
          buttons="few"
          v-validate="'required'"
          :data-vv-as="$t('deliveryMethods.EmailBody')"
          :label="$t('deliveryMethods.EmailBody')"
          v-model="curData.emailBody"
          :errorHint="errors.first('emailBody')"
          :state="!errors.has('emailBody')"
          key="deliverymethodform.emailBody"
          :placeholder="$t('deliveryMethods.EnterEmailBody')"
          ref="emailBodyField"
        />

        <mc-button class="float-right" :text="$t('general.Reset')" @click="resetEmail" />
      </b-card>
    </TransitionToAuto>

    <mc-form-multiinput
      class="mt-3"
      v-model="curData.additionalEmail"
      type="email"
      :label="$t('deliveryMethods.AdditionalEmail')"
      :description="$t('deliveryMethods.AdditionalEmailDescription')"
      :placeholder="$t('general.EnterEmail')"
      key="deliverimethodsform.additionalEmail"
      :disabled="fullCommission && !$perms.isAdministrator()"
      icon="at"
      ref="additionalEmail"
      :isValidating="isValidating"
    />

    <mc-form-multiinput
      v-model="curData.additionalSMS"
      type="telephone"
      :label="$t('deliveryMethods.AdditionalSMS')"
      :description="$t('deliveryMethods.AdditionalSMSDescription')"
      :placeholder="$t('general.EnterPhone')"
      key="deliverimethodsform.additionalSMS"
      :disabled="fullCommission && !$perms.isAdministrator()"
      icon="phone"
      ref="additionalSMS"
      :isValidating="isValidating"
    />
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmptyObject } from '@/helpers/Misc'
import DeliveryMethodsService from '@/services/DeliveryMethodsService'
import MerchantService from '@/services/MerchantService'
import { FormatError } from '@/helpers/ErrorFormatting'

const defaultData = {
  id: 0,
  commission: -1,
  userId: 0,
  headline: '',
  pricePerItem: 49,
  pricePerOrder: 0,
  description: '',
  image: null,
  additionalEmail: '',
  additionalSMS: '',
  requireAddress: false,
  requireDeliveryShop: false,
  attachCertificate: true,
  useOfflineGiftcard: false,
  emailBody: '',
  emailSubject: '',
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
      userList: [],
      fullCommission: false,
      defaultEmail: null,
      setEmailContent: false,
      isValidating: false,
    }
  },
  props: {
    value: Object,
    merchantId: Number,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      let newVal = {}

      for (var field in defaultData) {
        //console.log(field, val.hasOwnProperty('minFreeAmountInCents'))
        if (field === 'pricePerItem' && val.hasOwnProperty('priceInCentsPerItem')) {
          newVal[field] = val.priceInCentsPerItem / 100
        } else if (field === 'pricePerOrder' && val.hasOwnProperty('priceInCentsPerOrder')) {
          newVal[field] = val.priceInCentsPerOrder / 100
        } else {
          newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
        }
      }

      this.fullCommission = val.hasOwnProperty('commission') && val.commission == 80

      this.curData = newVal

      if (this.curData.emailSubject && this.curData.emailBody) {
        this.setEmailContent = true
      } else {
        this.setEmailContent = false
      }

      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)
    },
    fullCommission(v) {
      if (v) {
        this.curData.commission = 80
      } else {
        this.curData.commission = -1
      }
    },
    setEmailContent() {
      if (this.setEmailContent && this.curData.emailBody == '' && this.curData.emailSubject == '') {
        this.resetEmail()
      }
    },
    merchantId(merchantId) {
      this.loadingState = true
      this.defaultEmail = null
      MerchantService.users(merchantId)
        .then(data => {
          var theList = [{ value: 0, label: this.$t('deliveryMethods.ChooseUser') }]
          for (var n in data) {
            theList.push({ value: data[n].id, label: data[n].label })
          }
          this.userList = theList
          this.loadingState = false
        })
        .catch(error => {
          this.loadingState = FormatError(error)
        })
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    resetEmail() {
      this.getDefaultEmailOnce().then(() => {
        setTimeout(() => {
          this.$set(this.curData, 'emailSubject', this.defaultEmail.subject)
          this.$set(this.curData, 'emailBody', this.defaultEmail.body)
          this.$refs['emailBodyField'].setValue(this.defaultEmail.body)
        }, 50)
      })
    },

    getDefaultEmailOnce() {
      return new Promise((resolve, reject) => {
        if (!this.defaultEmail) {
          DeliveryMethodsService.getDefaultEmail(this.merchantId).then(
            data => {
              this.defaultEmail = data
              resolve()
            },
            error => {
              console.error('Error getting default email', error)
              reject(error)
            },
          )
        } else {
          resolve()
        }
      })
    },

    handleReset() {
      this.curData = { ...defaultData }
      this.isValidating = false
    },

    handleSubmit(e) {
      this.isValidating = true
      if (!this.$refs['additionalEmail'].isValid() || !this.$refs['additionalSMS'].isValid()) {
        return Promise.reject({ message: this.$tc('error.GeneralFormError', 1) })
      }
      let action = DeliveryMethodsService.create
      if (this.isEditing) {
        action = DeliveryMethodsService.update
      }
      let postData = { ...this.curData }
      postData.priceInCentsPerItem = 100 * this.curData.pricePerItem
      delete postData['pricePerItem']
      postData.priceInCentsPerOrder = 100 * this.curData.pricePerOrder
      delete postData['pricePerOrder']
      if (!this.setEmailContent) {
        postData['emailBody'] = ''
        postData['emailSubject'] = ''
      }
      return action(this.merchantId, postData).then(
        data => {
          this.toastSuccess(this.$t('general.ChangesSaved'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating delivery method', error)
          return Promise.reject(error)
        },
      )
    },
  },
  computed: {
    title() {
      return this.isEditing ? this.$t('deliveryMethods.EditDeliveryMethod') : this.$t('deliveryMethods.CreateNewDeliveryMethod')
    },
  },
}
</script>
