<template>
  <div class="pushContentBelowFixedHeader">
    <b-navbar fixed="top" toggleable="md" type="light" variant="white" class="shadow">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand to="/home"><img src="../assets/DanskeGavekort-logo-orig.png" width="600" height="150" /></b-navbar-brand>
      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav v-if="LoginStore.user">
          <b-nav-item to="/">{{ $t('headings.Home') }}</b-nav-item>
          <b-nav-item to="/orders" v-if="showOrdersAsTopmenu">{{ $t('headings.Orders') }}</b-nav-item>
          <b-nav-item-dropdown v-bind:text="$t('headings.OrdersEtc')" v-else-if="showOrdersMenu">
            <b-dropdown-item v-if="showOrders" to="/orders">{{ $t('headings.Orders') }}</b-dropdown-item>
            <b-dropdown-item v-if="showBilling" to="/billing">{{ $t('headings.Billing') }}</b-dropdown-item>
            <b-dropdown-item v-if="showReports" to="/reports">{{ $t('headings.Reports') }}</b-dropdown-item>
            <b-dropdown-item v-if="showOffline" to="/offline-giftcards">{{ $t('headings.OfflineGiftcards') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <!-- eslint-disable-next-line -->
          <b-nav-item-dropdown
            v-bind:text="$t('headings.Management')"
            v-if="$perms.isAdministrator() || $perms.isOwnerOfMerchants() || $perms.hasMerchantsWithPermission('manageCertificates') || $perms.hasMerchantsWithPermission('accessIntegration') || $perms.hasMerchantsWithPermission('handleCompany')"
          >
            <b-dropdown-item v-if="$perms.isAdministrator() || $perms.isOwnerOfMerchants()" to="/users">{{ $tc('headings.Users') }}</b-dropdown-item>
            <b-dropdown-item v-if="$perms.userId() == 1" to="/designs">{{ $t('headings.Designs') }}</b-dropdown-item>
            <b-dropdown-item v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('manageCertificates')" to="/gift-cards">{{ $t('headings.GiftCardTypes') }}</b-dropdown-item>
            <b-dropdown-item v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('manageCertificates')" to="/delivery-methods">{{ $t('headings.DeliveryMethods') }}</b-dropdown-item>
            <b-dropdown-item v-if="$perms.isAdministrator() || $perms.hasMerchantsWithPermission('accessIntegration')" to="/integration">{{ $t('headings.Integration') }}</b-dropdown-item>
            <b-dropdown-item v-if="$perms.isAdministrator() || $perms.isOwnerOfMerchants() || $perms.hasMerchantsWithPermission('handleCompany')" to="/merchants">{{ $tc('headings.Merchants', $perms.hasSeveralMerchants() ? 2 : 1) }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!--b-nav-item-dropdown text="Tests" right>
            <b-nav-item to="/test/toast">Toast</b-nav-item>
            <b-nav-item to="/test/permissions">Permissions</b-nav-item>
            <b-nav-item to="/test/uploads">Uploads</b-nav-item>
          </b-nav-item-dropdown-->

          <b-nav-item-dropdown v-bind:text="$t('general.Language')" right>
            <template #button-content>
              <img v-if="LanguageStore.currentLanguage == 'da'" class="lang-flag" src="../assets/flag-da.png" :title="$t('general.Language')" width="200" height="125" />
              <img v-if="LanguageStore.currentLanguage == 'en'" class="lang-flag" src="../assets/flag-en.png" :title="$t('general.Language')" width="200" height="125" />
            </template>
            <b-dropdown-item @click="setLang('da')">
              <img class="lang-flag" src="../assets/flag-da.png" title="Dansk" width="200" height="125" />
              Dansk
            </b-dropdown-item>
            <b-dropdown-item @click="setLang('en')">
              <img class="lang-flag" src="../assets/flag-en.png" title="English" width="200" height="125" />
              English
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item v-if="$perms.isQrUser()" to="/login" right>
            {{ $t('general.LogOut') }}
          </b-nav-item>
          <b-nav-item-dropdown v-else-if="LoginStore.user" right>
            <template slot="button-content">
              <div class="label-with-details">
                <em>{{ LoginStore.user.name }}</em>
              </div>
            </template>
            <b-dropdown-item to="/profile">{{ $t('headings.Profile') }}</b-dropdown-item>
            <b-dropdown-item to="/login" right>{{ $t('general.LogOut') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="!LoginStore.user && $route.path != '/login'" to="/login" right>
            {{ $t('general.LogIn') }}
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProfileService from '@/services/ProfileService'

export default {
  methods: {
    setLang: function (lang) {
      this.$store.dispatch('LanguageStore/setLang', lang)
      if (this.LoginStore.user) {
        ProfileService.setLanguage(lang)
      }
    },
  },
  computed: {
    ...mapState({
      LoginStore: state => state.LoginStore,
      LanguageStore: state => state.LanguageStore,
      showOrders() {
        return this.$perms.isAdministrator() || this.$perms.hasMerchantsWithPermission('seeOrders')
      },
      showBilling() {
        return this.$perms.isAdministrator() || this.$perms.hasMerchantsWithPermission('seeBilling')
      },
      showReports() {
        return this.$perms.isAdministrator() || this.$perms.hasMerchantsWithPermission('seeBilling')
      },
      showOffline() {
        return this.$perms.isAdministrator() || this.$perms.hasMerchantsWithPermission('manageOfflineGiftcards')
      },
      showOrdersAsTopmenu() {
        return this.showOrders && !this.showBilling && !this.showReports && !this.showOffline
      },
      showOrdersMenu() {
        return this.showOrders || this.showBilling || this.showReports || this.showOffline
      },
    }),
  },
}
</script>

<style>
.label-with-details {
  display: inline-block;
  vertical-align: top;
  position: relative;
}
.label-with-details small {
  display: block;
  /*position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;*/
  font-size: 10px;
  line-height: 1em;
}

@media (min-width: 768px) {
  .navbar-nav .dropdown-menu {
    display: block;
  }

  .navbar-nav .dropdown-menu {
    transform: scale(0.5, 0.001) translate(0, -10px);
    transform-origin: 50% 0%;
    transition: transform 0.2s ease-in-out;
  }

  .navbar-nav .dropdown-menu.show {
    transform: scale(1) translate(0, 0);
  }
}

.navbar-brand > img {
  height: 3em;
  width: auto;
}
@media (max-width: 400px) {
  .navbar-brand {
    margin-right: 0;
  }
  .navbar-brand img {
    height: auto;
    width: 9em;
  }
}

.pushContentBelowFixedHeader {
  height: 86px;
  flex-shrink: 0;
  @media (max-width: 400px) {
    height: 71px;
  }
}
.lang-flag {
  width: 40px;
  height: auto;
  margin-top: -5px;
}
</style>
