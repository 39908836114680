import { authorizedApiRequest } from '@/setup/ApiService'

export default {
  get: function (merchant) {
    return authorizedApiRequest(
      '/delivery-methods/' + merchant, //
      'GET',
    )
  },

  getDefaultEmail: function (merchant) {
    return authorizedApiRequest(
      '/delivery-methods/' + merchant + '/default-email', //
      'GET',
    )
  },

  create: function (merchant, deliveryMethod) {
    return authorizedApiRequest(
      '/delivery-methods/' + merchant, //
      'POST',
      deliveryMethod,
    )
  },

  clone: function (merchant, deliveryMethod) {
    return authorizedApiRequest(
      '/delivery-methods/' + merchant + '/' + deliveryMethod.id + '/clone', //
      'POST',
    )
  },

  update: function (merchant, deliveryMethod) {
    return authorizedApiRequest(
      '/delivery-methods/' + merchant + '/' + deliveryMethod.id, //
      'PUT',
      deliveryMethod,
    )
  },

  order: function (merchant, order) {
    return authorizedApiRequest(
      '/delivery-methods/' + merchant + '/order', //
      'PUT',
      { order },
    )
  },

  delete: function (merchant, deliveryMethod) {
    return authorizedApiRequest(
      '/delivery-methods/' + merchant + '/' + deliveryMethod.id, //
      'DELETE',
    )
  },
}
