<template>
  <McLayoutDefault :heading="$t('headings.OfflineGiftcards')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('manageOfflineGiftcards')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card class="mb-2">
          <p>{{ $t('offlineGiftcards.Description') }}</p>
          <MerchantSelector permission="manageOfflineGiftcards" v-model="merchantId" />
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <div class="mb-2 float-right d-flex">
          <McButton @click="showPrintModal" class="mr-2" variant="info" :icon="['far', 'print']" :text="$t('offlineGiftcards.Print')" />
          <CreateOrderButtonAndForm offline :merchantId="merchantId" @orderCreated="refreshTable" />
        </div>
        <FinalizeOfflineGiftcardForm isModal id="finalizeofflinegiftcardform" :selectedCard="offlineBase" @orderCreated="refreshTable" class="mb-2 float-right" />
        <McTable class="offlinegiftcards-table" ref="offlineGiftcardsTable" :emptyText="$t('offlineGiftcards.YouHaveNoOffline')" :sortBy="sortBy" sortDesc :primaryKey="primaryKey" :fields="fields" :provider="dataProvider">
          <template v-slot:cell(dateCreated)="data">
            {{ data.item.dateCreated | formatDate }}
          </template>
          <template v-slot:cell(priceInCents)="data">
            <span v-if="data.item.priceInCents > 0">{{ data.item.priceInCents | niceCurrency }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(dateExpires)="data">
            <span v-if="data.item.dateExpires !== '0000-00-00 00:00:00'">{{ data.item.dateExpires | formatDate }}</span>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(actions)="data">
            <!--pre>{{data.item}}</pre-->
            <b-button-group>
              <!-- eslint-disable-next-line -->
              <McButton
                :icon="['fas', 'file-check']"
                variant="success"
                size="sm"
                @click="finalizeGiftcard(data.item)"
              />
              <!-- eslint-disable-next-line -->
              <McButton
                :href="data.item.link"
                target="_blank"
                variant="primary"
                size="sm"
                :icon="['far', 'eye']"
              />
              <!-- eslint-disable-next-line -->
              <McButton
                :href="data.item.deliveryLink"
                target="_blank"
                variant="secondary"
                v-if="data.item.deliveryLink"
                size="sm"
                :icon="['far', 'print']"
              />
              <!-- eslint-disable-next-line -->
              <McButton
                icon="trash"
                variant="danger"
                size="sm"
                @click="deleteGiftcard(data.item.id, data.item.code)"
              />
            </b-button-group>
          </template>
        </McTable>
      </b-col>
    </b-row>
    <PrintOfflineGiftcardsForm isModal id="printofflinegiftcardsform" ref="printofflinegiftcardsform"></PrintOfflineGiftcardsForm>
  </McLayoutDefault>
</template>

<script>
import OfflineGiftcardsService from '@/services/OfflineGiftcardsService'
import moment from 'moment-timezone'
import { niceCurrency } from '@/helpers/Formatting'

export default {
  data() {
    return {
      primaryKey: 'id',
      sortBy: 'id',
      loadingState: true,
      merchantId: null,
      offlineBase: null,
      pdfLink: false,
    }
  },
  watch: {
    merchantId() {
      this.refreshTable()
    },
  },
  computed: {
    fields() {
      let retval = []
      retval.push({ key: 'dateCreated', priority: 1, mw: 120, sortable: true, label: this.$t('offlineGiftcards.DateCreated'), class: 'text-center' })
      retval.push({ key: 'code', priority: 2, mw: 140, sortable: true, label: this.$t('offlineGiftcards.Code') })
      retval.push({ key: 'giftcardHeadline', priority: 3, mw: 110, sortable: false, label: this.$t('offlineGiftcards.GiftcardType') })
      retval.push({ key: 'dateExpires', priority: 5, mw: 110, sortable: true, label: this.$t('general.Expiry'), class: 'text-left' })
      retval.push({ key: 'priceInCents', priority: 4, mw: 110, sortable: true, label: this.$t('offlineGiftcards.Amount'), class: 'text-right' })
      retval.push({ key: 'actions', priority: 0, mw: 110, label: this.$t('general.Actions'), class: 'text-right' })
      return retval
    },
  },
  methods: {
    dataProvider(ctx) {
      return OfflineGiftcardsService.query(ctx, this.merchantId)
    },
    refreshTable() {
      if (this.$refs.offlineGiftcardsTable) {
        this.$refs.offlineGiftcardsTable.refresh()
      }
    },
    finalizeGiftcard(item) {
      this.offlineBase = item
      this.$bvModal.show('finalizeofflinegiftcardform')
    },
    deleteGiftcard(id, code) {
      if (confirm(this.$t('offlineGiftcards.ConfirmDelete', { code }))) {
        OfflineGiftcardsService.delete(id).then(data => {
          this.refreshTable()
        })
      }
    },
    showPrintModal() {
      if (!this.merchantId) {
        this.$bvModal.msgBoxOk(this.$t('offlineGiftcards.PleaseSelectMerchantFirst'))
        return
      }
      this.$refs['printofflinegiftcardsform'].forceReset(this.merchantId)
      this.$bvModal.show('printofflinegiftcardsform')
    },
  },
  filters: {
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
    formatDate(date) {
      return moment(date).format('ll')
    },
  },
}
</script>
<style lang="scss">
.offlinegiftcards-table {
}
</style>
