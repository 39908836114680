<template>
  <b-form-group :label="label" :description="description">
    <b-form-group v-for="(field, key) in inputs" v-bind:key="field.id">
      <b-input-group :size="$attrs['size']">
        <b-input-group-text if="icon" slot="prepend" class="bg-primary text-white">
          <McIcon :icon="icon" />
        </b-input-group-text>
        <!-- prettier-ignore -->
        <b-form-input
          class="form-control"
          :state="!isValidating ? null : field.valid"
          @input="v => setValue(key, v)"
          :id="$id('focusid' + field.id)"
          :value="field.value"
          :placeholder="placeholder"
          @keydown.,.stop.prevent="addInput(key)"
          @keydown.backspace="maybeRemove(key)"
        >
        </b-form-input>
        <template slot="append">
          <b-button v-if="key === inputs.length - 1 && fieldsAreValid" @click="addInput(key)" variant="success" style="width: 2.5em">+</b-button>
          <b-button v-else-if="key < inputs.length - 1" @click="removeInput(key)" variant="danger" style="width: 2.5em">-</b-button>
        </template>
      </b-input-group>
      <TransitionToAuto v-if="isValidating && !field.valid && field.hint">
        <div class="invalid-feedback d-block">{{ field.hint }}</div>
      </TransitionToAuto>
      <TransitionToAuto v-else-if="isValidating && key === inputs.length - 1 && requiredAndEmpty">
        <div class="invalid-feedback d-block">{{ $t('general.RequiredField') }}</div>
      </TransitionToAuto>
    </b-form-group>
    <!--pre>{{ inputs }}</pre-->
  </b-form-group>
</template>

<script>
export default {
  components: {},
  computed: {},
  props: {
    icon: { type: [String, Array], default: 'at' },
    label: String,
    description: String,
    value: String,
    required: Boolean,
    isValidating: Boolean,
    placeholder: String,
    type: { type: [String], default: 'email' }
  },
  mounted() {
    this.setValueFromString(this.value)
  },
  methods: {
    setValueFromString(v) {
      let strings = v.split(',')
      let inputs = []
      for (let n in strings) {
        let input = { value: strings[n], valid: true, hint: '', id: this.getUniq() }
        if (this.inputs[n]) {
          input.id = this.inputs[n].id
        }
        inputs.push(input)
      }
      this.inputs = inputs
      this.doValidate()
    },
    isValid() {
      return this.fieldsAreValid || (!this.requiredAndEmpty && this.value === '')
    },
    addInput(afterKey) {
      if (!this.fieldsAreValid) {
        return
      }
      let newArr = []
      let newId = this.getUniq()
      for (let n in this.inputs) {
        newArr.push(this.inputs[n])
        if (n == afterKey) {
          newArr.push({ value: '', valid: true, hint: '', id: newId })
        }
      }
      this.inputs = newArr
      let newValue = ''
      for (let n in this.inputs) {
        newValue += ',' + this.inputs[n].value
      }
      this.$emit('input', newValue.substring(1))
      setTimeout(() => {
        document.querySelector("#" + this.$id('focusid' + newId)).focus()
      }, 100)
    },
    removeInput(key) {
      let newArr = this.inputs.slice()
      newArr.splice(key, 1)
      this.inputs = newArr
      let newValue = ''

      for (let n in this.inputs) {
        newValue += ',' + this.inputs[n].value
      }
      this.$emit('input', newValue.substring(1))

      let focusKey = 0
      if (key > 0) {
        focusKey = key - 1
      }
      let focusId = this.inputs[focusKey].id
      setTimeout(() => {
        document.querySelector("#" + this.$id('focusid' + focusId)).focus()
      }, 100)
    },
    maybeRemove(key) {
      if (this.inputs[key].value == '' && this.inputs.length > 1) {
        this.removeInput(key)
      }
    },
    setValue(key, v) {
      let newValue = ''
      for (let n in this.inputs) {
        if (n == key) {
          newValue += ',' + v
        } else {
          newValue += ',' + this.inputs[n].value
        }
      }
      this.$emit('input', newValue.substring(1))
    },
    doValidate() {
      for (let n in this.inputs) {
        let input = this.inputs[n]
        if (input.value == '') {
          input.hint = ''
          input.valid = false
        } else if (this.type === 'telephone' && !input.value.match(/^[0-9]{8}$/)) {
          input.hint = this.$t('general.PhoneMustBe8Digits')
          input.valid = false
        } else if (this.type === 'email' && !input.value.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/)) {
          input.hint = this.$t('general.EmailNotValid')
          input.valid = false
        } else {
          input.hint = ''
          input.valid = true
        }
      }
    },
    getUniq() {
      this.uniqId++
      return this.uniqId
    },
  },
  watch: {
    value(v) {
      this.setValueFromString(v)
    },
    isValidating(v) {
      this.doValidate()
    },
  },
  computed: {
    fieldsAreValid() {
      for (let n in this.inputs) {
        if (!this.inputs[n].valid) {
          return false
        }
      }
      return true
    },
    requiredAndEmpty() {
      if (!this.required) {
        return false
      }
      for (let n in this.inputs) {
        if (this.inputs[n].value.trim()) {
          return false
        }
      }
      return true
    },
  },
  data() {
    return {
      inputs: [{ value: '', valid: true, hint: '', id: 1 }],
      uniqId: 1,
    }
  },
}
</script>
