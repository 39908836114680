<template>
  <b-modal :id="$id('showCertificateModal')" :title="$t('general.ShowCertificate')" @ok="openPdf" hide-footer :ok-title="$t('general.OK')">
    <!--pre>{{ codeInfo }}</pre-->
    <b-button-group class="d-flex">
      <mc-button style="width: 50%; flex-grow: 1" variant="primary" target="_blank" @click="hide" :href="defaultPrintable.link" :text="$t('general.DefaultDesign')" />
      <mc-button style="width: 50%; flex-grow: 1" variant="secondary" v-if="deliveryPrintable" @click="hide" target="_blank" :href="deliveryPrintable.link" :text="$t('general.DeliveryDesign')" />
    </b-button-group>

    <!--div class="mt-4" v-if="nonStandardPrintables.length > 0">
      {{ $t('general.AllDesigns') }}
      <ul>
        <li v-for="printable in nonStandardPrintables" v-bind:key="printable.link">
          <a :href="printable.link" target="_blank">{{ printable.label }}</a>
        </li>
      </ul>
    </div-->
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex'
import CodesService from '@/services/CodesService'

export default {
  props: {
    codeInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      printables: [],
    }
  },
  computed: {
    nonStandardPrintables() {
      var ret = []
      for (var i in this.printables) {
        if (['default', 'delivery'].includes(this.printables[i].id)) {
          continue
        }
        ret.push(this.printables[i])
      }
      return ret
    },
    deliveryPrintable() {
      for (var i in this.printables) {
        if (this.printables[i].id == 'delivery') {
          return this.printables[i]
        }
      }
      return false
    },
    defaultPrintable() {
      for (var i in this.printables) {
        if (this.printables[i].id == 'default') {
          return this.printables[i]
        }
      }
      return false
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastWarning',
    ]),
    hide() {
      this.$bvModal.hide(this.$id('showCertificateModal'))
    },
    show() {
      if (this.codeInfo.type === 'offline') {
        this.printables = [{ id: 'default', link: this.codeInfo.link }]
        console.log(this.codeInfo)
        if (this.codeInfo.deliveryLink) {
          this.printables.push({ id: 'delivery', link: this.codeInfo.deliveryLink })
          this.$bvModal.show(this.$id('showCertificateModal'))
        } else {
          var anchor = document.createElement('a')
          anchor.href = this.defaultPrintable.link
          anchor.target = '_blank'
          anchor.click()
        }
        return
      }
      CodesService.printables(this.codeInfo.code).then(
        data => {
          this.printables = data.printables
          if (this.deliveryPrintable) {
            this.$bvModal.show(this.$id('showCertificateModal'))
          } else {
            var anchor = document.createElement('a');
            anchor.href = this.defaultPrintable.link;
            anchor.target = '_blank';
            anchor.click();
          }
        },
        error => {
          console.error('Error getting designs', error)
          return Promise.reject(error)
        },
      )
    },
    openPdf(e) {
      console.log(e)
    },
  },
}
</script>
