<template>
  <div class="designer-wrap">
    <div class="h-100 d-flex">
      <div class="h-100" style="width: 30%; position: relative">
        <div class="h-100 w-100 d-flex flex-column" v-if="currentDesign">
          <div v-if="!showPreview && !showImport && !showExport && !showDesignSettings" class="buttons-for-designer text-center border-bottom bg-light">
            <transition name="transition-for-buttons">
              <div v-if="!activeElement" key="a" class="p-2">
                <b-button @click="startDesignSettings" class="mr-2"><McIcon icon="cog" /></b-button>
                <b-dd variant="primary" :title="$t('designer.AddElement')">
                  <template #button-content>
                    <font-awesome-icon icon="plus" fixed-width />
                    {{ $t('designer.AddElement') }}
                  </template>
                  <b-dropdown-item-button @click="addPage">{{ $t('designer.elementTypes.page') }}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addImage">{{ $t('designer.elementTypes.image') }}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addTextarea">{{ $t('designer.elementTypes.textarea') }}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addShape">{{ $t('designer.elementTypes.shape') }}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addQR">{{ $t('designer.elementTypes.qr') }}</b-dropdown-item-button>
                  <b-dropdown-item-button @click="addValExp">{{ $t('designer.elementTypes.valexp') }}</b-dropdown-item-button>
                </b-dd>
              </div>
              <div v-else key="b" class="p-2">
                <b-button-group>
                  <mc-button variant="info" icon="clone" @click="cloneActiveElement" :text="$t('designer.CloneElement')" />
                  <mc-button variant="danger" icon="trash" @click="deleteActiveElement" :text="$t('designer.DeleteElement')" />
                </b-button-group>
              </div>
            </transition>
          </div>
          <div class="layers-and-element-editor" :style="'margin-left: ' + (activeElement || showPreview || showImport || showExport || showDesignSettings ? '-100%' : '0')">
            <div class="layers">
              <!-- eslint-disable-next-line-->
              <b-card class="m-2 mb-5" no-body header-class="p-0" v-for="(pageElm, pageKey) in currentDesign.elements">
                <template #header>
                  <div class="designer-element-position d-flex p-2">
                    <McFormInput
                      style="width: 50%"
                      type="number"
                      size="sm"
                      unit="mm"
                      :max="500"
                      :min="40"
                      :step="1 / pixelsPerMm"
                      :label="$t('general.Width')"
                      v-model="currentDesign.elements[pageKey].width"
                    />
                    <McFormInput
                      style="width: 50%"
                      type="number"
                      size="sm"
                      unit="mm"
                      :max="500"
                      :min="40"
                      :step="1 / pixelsPerMm"
                      :label="$t('general.Height')"
                      v-model="currentDesign.elements[pageKey].height"
                    />
                    <b-button-group size="sm" class="ml-2 m-1" style="align-self: center">
                      <McButton :icon="['far', 'eye']" :title="$t('designer.CloneElement')" @click="currentPageKey = pageKey" variant="success" />
                      <McButton icon="clone" :title="$t('designer.CloneElement')" @click="clonePage(pageKey)" variant="info" />
                      <McButton icon="trash" :title="$t('designer.DeleteElement')" @click="deletePage(pageKey)" variant="danger" />
                    </b-button-group>
                  </div>
                </template>
                <draggable tag="div" :list="currentDesign.elements[pageKey].elements" swapThreshold=".65" fallbackOnBody animation="100" :group="{ name: 'g1' }">
                  <DesignerListElement
                    ref="listItem"
                    v-for="(element, key) in pageElm.elements"
                    v-bind:key="key"
                    @select="() => selectActiveElementKey(pageKey, key)"
                    @clone="() => cloneElement(pageKey, key)"
                    @delete="() => deleteElement(pageKey, key)"
                    :element="element"
                    :isCurrentPage="currentPageKey == pageKey"
                  />
                </draggable>
              </b-card>
            </div>
            <transition name="transition-for-element-editor">
              <div class="designer-preview-textpanel p-5" v-if="showPreview" key="previewPanel">
                <div v-html="$t('designer.previewPanelText')" />
                <div class="text-center">
                  <mc-button variant="outline-info" :icon="['fad', 'cloud-download']" target="_blank" :href="downloadUrl ? downloadUrl : ''" :text="$t('designer.DownloadExample')" />
                </div>
              </div>
              <div class="designer-designsettings-panel p-5" v-else-if="showDesignSettings" key="designSettingsPanel">
                <h3>{{ $t('designer.DesignSettings') }}</h3>
                <mc-form-input v-model="currentDesign.title" type="text" :label="$t('designer.Title')" icon="signature" />
                <mc-form-select v-model="currentDesign.previewGiftcard" :options="previewCardOptions" :label="$t('designer.PreviewGiftcard')" :icon="['fad', 'file-pdf']" />
                <!--pre>{{ allCardsFlattened }}</pre>
                <pre>{{ allCards }}</pre-->
              </div>
              <div class="designer-preview-textpanel p-5" v-else-if="showImport" key="importPanel">
                <h3>{{ $t('general.Import') }}</h3>
                <textarea style="font-family: monospace; font-size: 0.8em; height: 10em" v-model="strDesign2import" />
                <p>{{ $t('general.PasteToImport') }}</p>
                <mc-button @click="doImport" variant="success" icon="file-import" :title="$t('general.Import')" :text="$t('general.Import')" />
              </div>
              <div class="designer-preview-textpanel p-5" v-else-if="showExport" key="exportPanel">
                <h3>{{ $t('general.Export') }}</h3>
                <textarea style="font-family: monospace; font-size: 0.8em; height: 10em" :value="currentDesign4export" />
                <p>{{ $t('general.CopyTheAboveToExport') }}</p>
              </div>
              <div class="designer-element-editor-wrap" v-else-if="activeElement" v-bind:key="activeElementKey">
                <DesignerEditor
                  :element="activeElement"
                  :pixelsPerMm="pixelsPerMm"
                  @moved="newPosition => (activeElement.position = newPosition)"
                  @change-prop="data => changeProp(activeElement, data)"
                  @delete="deleteActiveElement"
                  ref="elementEditor"
                />
              </div>
            </transition>
          </div>
          <div class="buttons-for-designer text-center border-top bg-light">
            <transition name="transition-for-buttons">
              <div v-if="showPreview || showImport || showExport || showDesignSettings" key="a" class="p-2">
                <div class="float-left">
                  <mc-button @click="showPreview = showImport = showExport = showDesignSettings = false" variant="primary" :icon="['far', 'arrow-left']" :text="$t('designer.Back')" />
                </div>
              </div>
              <div v-else-if="!activeElement" key="b" class="p-2">
                <b-button variant="outline-secondary" @click="closeIfUnchanged" class="mr-2">{{ $t('general.Close') }}</b-button>
                <b-dropdown dropup split variant="info" @click="previewDesign(false)" class="mr-2">
                  <template #button-content><mc-icon icon="eye" /> {{ $t('designer.ShowPreview') }}</template>
                  <b-dropdown-item @click="previewDesign(true)">{{ $t('designer.ShowBlank') }}</b-dropdown-item>
                </b-dropdown>
                <b-dropdown dropup split icon="save" variant="success" @click="saveDesignChanges" class="mr-2">
                  <template #button-content><mc-icon icon="save" /> {{ $t('designer.SaveDesign') }}</template>
                  <b-dropdown-item @click="startImport"><McIcon icon="file-import" /> {{ $t('general.Import') }}</b-dropdown-item>
                  <b-dropdown-item @click="startExport"><McIcon icon="file-export" /> {{ $t('general.Export') }}</b-dropdown-item>
                </b-dropdown>
              </div>
              <div v-else key="c" class="p-2">
                <div class="float-left">
                  <mc-button @click="deselectActiveElementKey" variant="primary" :icon="['far', 'arrow-left']" :text="$t('designer.Back')" />
                </div>
              </div>
            </transition>
          </div>
        </div>
        <McSpinner v-else centercenter large />
      </div>
      <div class="position-relative h-100" style="width: 70%">
        <div class="zoom-control-wrap">
          <font-awesome-icon style="opacity: 0.7; position: absolute; top: 0.2rem; left: 0.2rem" icon="search-plus" fixed-width />
          <div class="zoom-control">
            <b-input type="range" :min="minZoom" :max="maxZoom" step="0.02" v-model="zoom" />
          </div>
          <font-awesome-icon style="opacity: 0.7; position: absolute; bottom: 0.2rem; left: 0.2rem" icon="search-minus" fixed-width />
        </div>
        <div class="pdfPreviewBackground" v-if="showPreview">
          <embed class="pdfPreview" type="application/pdf" v-if="showPreview && previewUrl" :src="previewUrl" width="100%" height="100%" />
          <McSpinner large />
        </div>
        <div class="canvas w-100" ref="canvas" @scroll="setScroll" @mousedown="deselectActiveElementKey">
          <div class="zoomer" :style="zoomerStyle">
            <div class="paper" ref="paper" :style="paperStyle">
              <div :style="outsidePaperStyle" />
              <div v-if="currentDesign" style="position: relative; z-index: 1">
                <DesignerViewElement
                  v-for="(element, key) in currentPageElements"
                  :style="{ zIndex: 10000 - key }"
                  @mousedown.stop="e => maybesetactiveelm(e, key)"
                  @moved="newPosition => (element.position = newPosition)"
                  :container="$refs['paper']"
                  v-bind:key="key"
                  :giftcard="giftcard"
                  :merchant="merchant"
                  :element="element"
                  :bounds="bounds"
                  :horizontalGuidelines="horizontalGuidelines"
                  :verticalGuidelines="verticalGuidelines"
                  :zoom="zoom"
                  :pixelsPerMm="pixelsPerMm"
                />
              </div>
              <McSpinner v-else centercenter large />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="save-design-modal" :title="$t('designer.SaveDesign')">
      {{ $t('designer.DoYouWantToSaveTheDesign') }}
      <template #modal-footer>
        <b-button size="sm" variant="danger" @click="discardDesignChanges">{{ $t('designer.DiscardChanges') }}</b-button>
        <b-button size="sm" variant="secondary" @click="$bvModal.hide('save-design-modal')">{{ $t('designer.Cancel') }}</b-button>
        <b-button size="sm" variant="success" @click="saveDesignChangesAndClose">{{ $t('designer.SaveChanges') }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import DesignsService from '../services/DesignsService'

export default {
  data() {
    return {
      mounted: false,
      progScrolling: false,
      zoom: 0.8,
      minZoom: 0.06,
      maxZoom: 1.0,
      currentDesign: false,
      pixelsPerMm: 10,
      scrollPosition: {
        x: 0.5,
        y: 0.5,
      },
      showPreview: false,
      previewUrl: false,
      downloadUrl: false,
      showImport: false,
      strDesign2import: '',
      showExport: false,
      showDesignSettings: false,
      lastActiveElements: {},
      origData: null,
      loadedfonts: [],
      exampleAmount: null,
      currentPageKey: 0,
    }
  },
  props: {
    merchantId: Number,
    merchant: Object,
    allCards: Array,
  },
  computed: {
    currentPageElements() {
      if (!this.currentDesign || !this.currentDesign.elements[this.currentPageKey]) {
        return []
      }
      return this.currentDesign.elements[this.currentPageKey].elements
    },
    activeElementKey() {
      for (var i in this.currentPageElements) {
        if (this.currentPageElements[i].isActive) {
          return i
        }
      }
      return null
    },
    allCardsFlattened() {
      return this.flattenCards(this.allCards)
    },
    previewCardOptions() {
      let ret = []
      ret.push({ value: -1, label: this.$t('designer.UnspecifiedPreviewGiftcard') })
      for (let i in this.allCardsFlattened) {
        ret.push({ value: this.allCardsFlattened[i].id, label: this.allCardsFlattened[i].prefix + this.allCardsFlattened[i].headline })
      }
      return ret
    },
    giftcard() {
      for (var i in this.allCardsFlattened) {
        if (this.allCardsFlattened[i].id == this.currentDesign.previewGiftcard) {
          return this.allCardsFlattened[i]
        }
      }
      for (var i in this.allCardsFlattened) {
        return this.allCardsFlattened[i]
      }
      return {
        exampleAmount: 30000,
        validity: 730,
      }
    },
    activeElement() {
      if (this.activeElementKey) {
        console.log(this.currentPageKey, this.activeElementKey, this.currentDesign.elements[this.currentPageKey].elements[this.activeElementKey])
        return this.currentDesign.elements[this.currentPageKey].elements[this.activeElementKey]
      }
      return null
    },
    papersize() {
      if (this.currentDesign && this.currentDesign.elements[this.currentPageKey]) {
        return { width: this.currentDesign.elements[this.currentPageKey].width * this.pixelsPerMm, height: this.currentDesign.elements[this.currentPageKey].height * this.pixelsPerMm }
      }
      return { width: 210 * this.pixelsPerMm, height: 297 * this.pixelsPerMm }
    },
    bounds() {
      return { left: -100 * this.pixelsPerMm, top: -100 * this.pixelsPerMm, bottom: this.papersize.height + 100 * this.pixelsPerMm, right: this.papersize.width + 100 * this.pixelsPerMm }
    },
    horizontalGuidelines() {
      return [0, this.papersize.height / 2, this.papersize.height]
    },
    verticalGuidelines() {
      return [0, this.papersize.width / 2, this.papersize.width]
    },
    zoomerStyle() {
      if (!this.mounted) {
        return {}
      }
      let marginX = Math.max(0, (this.$refs['canvas'].clientWidth - (this.papersize.width + 100 * this.pixelsPerMm) * this.zoom) / 2)
      let marginY = Math.max(0, (this.$refs['canvas'].clientHeight - (this.papersize.height + 100 * this.pixelsPerMm) * this.zoom) / 2)
      return {
        width: this.papersize.width + 100 * this.pixelsPerMm + 'px',
        height: this.papersize.height + 100 * this.pixelsPerMm + 'px',
        margin: marginY + 'px ' + marginX + 'px',
        transform: 'scale(' + this.zoom + ')',
      }
    },
    outsidePaperStyle() {
      if (!this.mounted) {
        return {}
      }
      let height = Math.max(this.$refs['canvas'].clientHeight / this.zoom, this.papersize.height + 100 * this.pixelsPerMm)
      let width = Math.max(this.$refs['canvas'].clientWidth / this.zoom, this.papersize.width + 100 * this.pixelsPerMm)
      let my = height - this.papersize.height
      let mx = width - this.papersize.width
      let left = mx / 2
      let top = my / 2
      let bottom = height - my / 2
      let right = width - mx / 2
      return {
        clipPath: 'polygon(0% 0%, 0% 100%, ' + left + 'px 100%, ' + left + 'px ' + top + 'px, ' + right + 'px ' + top + 'px, ' + right + 'px ' + bottom + 'px, ' + left + 'px ' + bottom + 'px, ' + left + 'px 100%, 100% 100%, 100% 0%)',
        position: 'absolute',
        zIndex: 99999,
        backgroundColor: '#000',
        height: height + 'px',
        width: width + 'px',
        top: '-' + top + 'px',
        left: '-' + left + 'px',
        pointerEvents: 'none',
        opacity: 0.8,
      }
    },
    paperStyle() {
      return {
        height: this.papersize.height + 'px',
        width: this.papersize.width + 'px',
        top: this.pixelsPerMm * 50 + 'px',
        left: this.pixelsPerMm * 50 + 'px',
      }
    },
    loadFonts() {
      // Load any fonts in the design
      let allFonts = []
      for (var n in this.currentDesign.elements) {
        for (var i in this.currentDesign.elements[n].elements) {
          if (['textarea', 'valexp'].includes(this.currentDesign.elements[n].elements[i].type)) {
            allFonts.push(this.currentDesign.elements[n].elements[i].font)
          }
        }
      }
      return allFonts
    },
    currentDesign4export() {
      return btoa(unescape(encodeURIComponent(JSON.stringify({ version: this.currentDesign.version, elements: this.currentDesign.elements }))))
    },
  },
  watch: {
    loadFonts() {
      for (var i in this.loadFonts) {
        let font = this.loadFonts[i]
        if (!this.loadedfonts.includes(font)) {
          const link = document.createElement('link')
          const sane = font
            .replaceAll(' ', '_')
            .replaceAll(/[^a-zA-Z0-9_]/g, '')
            .toLowerCase()
          const fontUrl = 'https://api.danskegavekort.dk/assets/fonts-web/' + sane + '.css'
          link.rel = 'stylesheet'
          link.href = fontUrl
          document.head.appendChild(link)
        }
      }
    },
    zoom() {
      window.requestAnimationFrame(() => {
        let elm = this.$refs['canvas']
        let maxScroll = {
          x: elm.scrollWidth - elm.clientWidth,
          y: elm.scrollHeight - elm.clientHeight,
        }
        this.progScrolling = true

        elm.scrollLeft = maxScroll.x * this.scrollPosition.x
        elm.scrollTop = maxScroll.y * this.scrollPosition.y
        this.stopProgScrolling()
      })
    },
    activeElement(element) {
      if (element) {
        this.lastActiveElements[element.type] = { ...element }
      }
    },
    currentPageKey() {
      this.setBestZoom()
    },
  },
  mounted() {
    this.mounted = true
    this.setBestZoom()
    this.$refs['canvas'].addEventListener('wheel', this.handleScroll, {
      passive: false,
    })

    window.addEventListener('beforeunload', this.warnWhenLeaving)
  },
  beforeDestroy() {
    this.$refs['canvas'].removeEventListener('wheel', this.handleScroll)
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
      'toastDanger',
    ]),
    setBestZoom() {
      let bestZoomX = this.$refs['canvas'].clientWidth / (this.papersize.width + 20 * this.pixelsPerMm)
      let bestZoomY = this.$refs['canvas'].clientHeight / (this.papersize.height + 20 * this.pixelsPerMm)
      this.zoom = Math.floor(Math.min(bestZoomX, bestZoomY) * 50) / 50
    },
    handleScroll(e) {
      //console.log(e.deltaY)
      if (e.ctrlKey) {
        let newZoom = this.zoom * (e.deltaY > 0 ? 0.96 : 1.05)
        this.zoom = Math.min(this.maxZoom, Math.max(this.minZoom, newZoom))
        e.preventDefault()
      }
    },
    flattenCards(cards, prefix) {
      let ret = []
      if (!prefix) {
        //
        prefix = ''
      }
      for (var i in cards) {
        if (cards[i].type === 'category') {
          let children = this.flattenCards(cards[i].children, prefix + cards[i].webshopName + ' - ')
          for (var n in children) {
            ret.push(children[n])
          }
        } else {
          cards[i].prefix = prefix

          cards[i].exampleAmount = cards[i].priceInCents
          if (!cards[i].exampleAmount) {
            cards[i].exampleAmount = cards[i].defFreeAmountInCents
          }

          ret.push(cards[i])
        }
      }
      return ret
    },
    getDesign() {
      let ret = JSON.parse(JSON.stringify(this.currentDesign))
      for (var i in ret.elements) {
        delete ret.elements[i].isActive
      }
      return ret
    },
    setDesign(newVal) {
      setTimeout(() => {
        if (newVal == null) {
          this.currentDesign = false
          return
        }

        // Totally disconnect any objects and make sure required attributes exists
        this.currentDesign = Object.assign({ title: '', previewGiftcard: -1, version: '2.0', elements: [] }, JSON.parse(JSON.stringify(newVal)))

        if (this.currentDesign.version < '3.0') {
          // Update designs to newest version first time they're saved
          this.currentDesign.elements = [
          {
              type: 'page',
              width: 210,
              height: 297,
              elements: JSON.parse(JSON.stringify(this.currentDesign.elements)),
            },
          ]
          this.currentDesign.version = '3.0'
        }

        for (var n in this.currentDesign.elements) {
          // Ensure any attributes that have been added "recently" exist on all elements,
          // so editor won't break when loading old design after adding new options to
          // elements - and add indicator of active state used in designer
          for (var i in this.currentDesign.elements[n].elements) {
            let oldEl = this.currentDesign.elements[n].elements[i]
            let el = Object.assign(this.getDefaultElement(oldEl.type), oldEl, { isActive: false })
            this.currentDesign.elements[n].elements[i] = el
          }
        }
        this.origData = JSON.stringify(this.getDesign())

        if (this.currentDesign.title === '') {
          this.showDesignSettings = true
        }
      }, 1)
    },

    warnWhenLeaving(e) {
      let nowData = JSON.stringify(this.getDesign())
      if (this.origData !== nowData) {
        var confirmationMessage = this.$t('general.IfYouLeaveYourChangesWillBeLost')
        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
    },

    closeIfUnchanged() {
      let nowData = JSON.stringify(this.getDesign())
      if (this.origData === nowData) {
        this.discardDesignChanges()
      }
      this.$bvModal.show('save-design-modal')
    },

    discardDesignChanges() {
      window.removeEventListener('beforeunload', this.warnWhenLeaving)
      this.$emit('cancel')
    },

    saveDesignChangesAndClose() {
      this.saveDesignChanges().then(() => {
        this.discardDesignChanges()
      })
    },
    saveDesignChanges() {
      var design = this.getDesign()
      let method
      if (design.id) {
        method = DesignsService.update(this.merchantId, design)
      } else {
        method = DesignsService.create(this.merchantId, design)
      }
      return method
        .then(() => {
          this.toastSuccess(this.$t('designer.DesignWasSaved'))
          this.origData = JSON.stringify(design)
          this.$emit('saved')
        })
        .catch(error => {
          alert(error)
        })
    },

    maybesetactiveelm(e, key) {
      this.selectActiveElementKey(this.currentPageKey, key)
      /*
      Would be nice to prevent active from changing when you mousedown something that is on top of the currently active. But how do we get the right element to drag?
      if (!this.hitsActive(e.clientX, e.clientY)) {
      }
    },
    hitsActive(x, y) {
      let element,
        elements = [],
        old_visibility = [],
        hitsActive = false
      while (true) {
        element = document.elementFromPoint(x, y)
        if (!element || element === document.documentElement) {
          break
        } else if (element.classList.contains('active_design_element')) {
          hitsActive = true
          break
        } else if (!element.classList.contains('mc_moveable')) {
          break
        }
        elements.push(element)
        old_visibility.push(element.style.visibility)
        element.style.visibility = 'hidden'
      }
      for (var k = 0; k < elements.length; k++) {
        elements[k].style.visibility = old_visibility[k]
      }
      return hitsActive*/
    },
    changeProp(element, data) {
      console.log('changeProp', data)
      this.$set(element, data[0], data[1])
    },
    stopProgScrolling: debounce(function () {
      this.progScrolling = false
    }, 200),
    setScroll: function (e) {
      if (this.progScrolling) return
      let elm = e.target
      let maxScroll = {
        x: elm.scrollWidth - elm.clientWidth,
        y: elm.scrollHeight - elm.clientHeight,
      }
      this.scrollPosition = {
        x: maxScroll.x > 10 ? elm.scrollLeft / maxScroll.x : 0.5,
        y: maxScroll.y > 10 ? elm.scrollTop / maxScroll.y : 0.5,
      }
    },
    clonePage(pageKey) {
      let newPages = []
      for (var i in this.currentDesign.elements) {
        if (i == pageKey) {
          newPages.push(JSON.parse(JSON.stringify(this.currentDesign.elements[i])))
        }
        newPages.push(this.currentDesign.elements[i])
      }
      this.currentDesign.elements = newPages
      if (this.currentPageKey >= pageKey) {
        this.currentPageKey++
      }
    },
    deletePage(pageKey) {
      if (confirm(this.$t('designer.ConfirmDeleteElement', { label: 'page' }))) {
        let newPages = []
        for (var i in this.currentDesign.elements) {
          if (i != pageKey) {
            newPages.push(this.currentDesign.elements[i])
          }
        }
        this.currentDesign.elements = newPages
        if (this.currentPageKey >= pageKey) {
          this.currentPageKey--
        }
      }
    },
    selectActiveElementKey(pageKey, key) {
      console.log(pageKey, key)
      this.currentPageKey = pageKey
      let ret = []
      for (var i in this.currentDesign.elements[pageKey].elements) {
        ret.push(Object.assign({}, this.currentDesign.elements[pageKey].elements[i], { isActive: key == i }))
      }
      this.currentDesign.elements[pageKey].elements = ret
      setTimeout(() => {
        this.$refs['listItem'][key] && this.$refs['listItem'][key].$el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }, 1)
    },
    deleteElement(pageKey, key) {
      let ret = []
      let confirmed = false
      for (var i in this.currentDesign.elements[pageKey].elements) {
        if (key == i) {
          confirmed = confirm(this.$t('designer.ConfirmDeleteElement', { label: this.currentDesign.elements[pageKey].elements[i].label }))
        } else {
          ret.push(Object.assign({}, this.currentDesign.elements[pageKey].elements[i]))
        }
      }
      if (confirmed) {
        this.currentDesign.elements[pageKey].elements = ret
      }
    },
    cloneElement(pageKey, key) {
      let ret = []
      for (var i in this.currentDesign.elements[pageKey].elements) {
        if (key == i) {
          let newElm = Object.assign({}, JSON.parse(JSON.stringify(this.currentDesign.elements[pageKey].elements[i])), { isActive: true })
          this.overrideNewElementAttributes(newElm, true)
          ret.push(newElm)
        }
        ret.push(Object.assign({}, this.currentDesign.elements[pageKey].elements[i], { isActive: false }))
      }
      this.currentDesign.elements[pageKey].elements = ret
      this.focusOnLabel()
    },
    deleteActiveElement() {
      this.deleteElement(this.currentPageKey, this.activeElementKey)
    },
    cloneActiveElement() {
      this.cloneElement(this.currentPageKey, this.activeElementKey)
      this.focusOnLabel()
    },
    deselectActiveElementKey(e) {
      if (e.target.classList.contains('moveable-control')) {
        return
      }
      for (var i in this.currentDesign.elements[this.currentPageKey].elements) {
        this.currentDesign.elements[this.currentPageKey].elements[i].isActive = false
      }
    },
    getDefaultElement(type) {
      switch (type) {
        case 'image':
          return {
            type: type,
            image: null,
            sizing: 'contain',
            useBackground: false,
            backcolor: '#eeeeffff',
            padding: 5,
            position: {
              left: this.papersize.width / (2 * this.pixelsPerMm) - 30,
              top: this.papersize.height / (2 * this.pixelsPerMm) - 30,
              width: 60,
              height: 60,
              rotation: 0,
            },
          }
          break
        case 'textarea':
          return {
            type: type,
            font: 'Open Sans',
            fontSize: 16,
            fontWeight: 400,
            fontWeightBold: 700,
            fontSpacing: 0,
            fromMetaIfExists: '',
            textAlign: 'left',
            useBackground: false,
            cornerRounding: 0,
            backcolor: '#eeeeffff',
            padding: 10,
            forecolor: '#333333ff',
            position: {
              left: this.papersize.width / (2 * this.pixelsPerMm) - 60,
              top: this.papersize.height / (2 * this.pixelsPerMm) - 30,
              width: 120,
              height: 60,
              rotation: 0,
            },
          }
          break
        case 'shape':
          return {
            type: type,
            backcolor: '#eeeeffff',
            shape: 'rectangle',
            cornerRounding: 0,
            position: {
              left: this.papersize.width / (2 * this.pixelsPerMm) - 50,
              top: this.papersize.height / (2 * this.pixelsPerMm) - 50,
              width: 100,
              height: 100,
              rotation: 0,
            },
          }
          break
        case 'qr':
          return {
            type: type,
            forecolor: '#000000ff',
            backcolor: '#ffffffff',
            position: {
              left: this.papersize.width / (2 * this.pixelsPerMm) - 50,
              top: this.papersize.height / (2 * this.pixelsPerMm) - 50,
              width: 30,
              height: 30,
              rotation: 0,
            },
          }
          break
        case 'valexp':
          let ret = this.getDefaultElement('textarea')
          ret.type = type
          ret.textAlign = 'justify'
          ret.layout = 'layout-1'
          ret.fontSizes = 'multiple'
          ret.blankStyle = 'blank'
          ret.layoutOptions = ['separator', 'credit']
          ret.separatorWidth = 0.5
          ret.cornerRounding = 0
          return ret
          break
      }
    },
    getNewElement(elementType) {
      let el = this.getDefaultElement(elementType)
      if (this.lastActiveElements[elementType]) {
        el = Object.assign(el, JSON.parse(JSON.stringify(this.lastActiveElements[elementType])))
      } else {
        for (var i in this.currentDesign.elements) {
          if (this.currentDesign.elements[i].type == elementType) {
            el = Object.assign(el, JSON.parse(JSON.stringify(this.currentDesign.elements[i])))
            break
          }
        }
      }

      el.isActive = false
      switch (elementType) {
        case 'image':
          el.image = null
          break
        case 'textarea':
          el.content = ''
          break
      }

      this.overrideNewElementAttributes(el)

      return el
    },

    overrideNewElementAttributes(el, isClone = false) {
      this.adjustPositionToNotBeOnTop(el)
      this.setUniqueLabel(el, isClone)
    },

    setUniqueLabel(el, isClone) {
      let baseLabel = isClone ? el.label + this.$t('designer.dashClone') : this.$t('designer.elementTypes.' + el.type)
      let extraLabel = isClone ? '' : 1
      let repeat
      let fullLabel
      do {
        repeat = false
        fullLabel = baseLabel + (extraLabel ? ' ' + extraLabel : '')
        for (var i in this.currentDesign.elements) {
          let otherEl = this.currentDesign.elements[i]
          if (otherEl.label == fullLabel) {
            repeat = true
            extraLabel = extraLabel ? parseInt(extraLabel) + 1 : 2
          }
        }
      } while (repeat)
      el.label = fullLabel
    },
    adjustPositionToNotBeOnTop(el) {
      let repeat
      let rounds = 10
      do {
        rounds--
        repeat = false
        for (var i in this.currentDesign.elements) {
          let otherEl = this.currentDesign.elements[i]
          if (JSON.stringify(otherEl.position) == JSON.stringify(el.position)) {
            repeat = true
            el.position.top += 10
          }
        }
      } while (repeat && rounds > 0)
    },

    addImage() {
      this.currentDesign.elements[this.currentPageKey].elements.unshift(this.getNewElement('image'))
      this.selectActiveElementKey(this.currentPageKey, 0)
      this.focusOnLabel()
    },
    addPage() {
      var newPage = JSON.parse(JSON.stringify(this.currentDesign.elements[this.currentPageKey]))
      newPage.elements = []
      this.currentDesign.elements.unshift(newPage)
      this.currentPageKey = 0
    },
    addTextarea() {
      this.currentDesign.elements[this.currentPageKey].elements.unshift(this.getNewElement('textarea'))
      this.selectActiveElementKey(this.currentPageKey, 0)
      this.focusOnLabel()
    },
    addShape() {
      this.currentDesign.elements[this.currentPageKey].elements.unshift(this.getNewElement('shape'))
      this.selectActiveElementKey(this.currentPageKey, 0)
      this.focusOnLabel()
    },
    addQR() {
      this.currentDesign.elements[this.currentPageKey].elements.unshift(this.getNewElement('qr'))
      this.selectActiveElementKey(this.currentPageKey, 0)
      this.focusOnLabel()
    },
    addValExp() {
      this.currentDesign.elements[this.currentPageKey].elements.unshift(this.getNewElement('valexp'))
      this.selectActiveElementKey(this.currentPageKey, 0)
      this.focusOnLabel()
    },
    focusOnLabel() {
      setTimeout(() => {
        if (this.$refs['elementEditor']) {
          this.$refs['elementEditor'].focusOnLabel()
        }
      }, 1)
    },
    previewDesign(blank) {
      this.showPreview = true
      this.previewUrl = false
      this.downloadUrl = false
      console.log('getdesign', this.getDesign())
      DesignsService.preview(this.merchantId, this.getDesign(), blank).then(ret => {
        this.previewUrl = ret.viewUrl
        this.downloadUrl = ret.downloadUrl
      })
    },
    startImport() {
      this.showImport = true
    },
    doImport() {
      try {
        let newDesign = JSON.parse(decodeURIComponent(escape(window.atob(this.strDesign2import))))
        if (!newDesign || typeof newDesign !== 'object' || !newDesign.hasOwnProperty('version')) {
          console.log(newDesign, typeof newDesign)
          throw 'Nonono!'
        }
        //console.log('importing', newDesign)
        this.currentDesign.version = newDesign.version
        this.currentDesign.elements = newDesign.elements
        this.toastSuccess(this.$t('general.ImportCompleted'))
      } catch (e) {
        this.toastDanger(this.$t('general.ImportFailed'))
      }
    },
    startExport() {
      this.showExport = true
    },
    startDesignSettings() {
      this.showDesignSettings = true
    },
  },
}
</script>

<style lang="scss">
.designer-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  .canvas,
  .designer-element-editor-wrap,
  .designer-preview-textpanel,
  .designer-designsettings-panel,
  .layers {
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    min-height: 120px;
  }
  .zoomer {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0 0;
  }

  .layers-and-element-editor {
    width: 200%;
    position: relative;
    display: flex;
    transition: margin 0.2s;
    flex-grow: 1;
    overflow: hidden;
  }
  .layers,
  .designer-preview-textpanel,
  .designer-designsettings-panel,
  .designer-element-editor-wrap {
    width: 50%;
    height: 100%;
    overflow-y: scroll;
  }
  .designer-preview-textpanel,
  .designer-designsettings-panel,
  .designer-element-editor-wrap {
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity 0.2s, transform 0.2s;
  }
  .designer-preview-textpanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .designer-designsettings-panel {
    ul {
      padding: 0;
      list-style: none;
    }
    ul ul {
      padding: 0 0 0 1em;
    }
  }
  .canvas {
    overflow: scroll;
    height: 100%;
    background: #ddd;
  }
  .zoom-control-wrap {
    position: absolute;
    top: 0;
    left: 0;
    height: 15rem;
    z-index: 9;
    input[type='range'] {
      &::-ms-track {
        background: #fff;
      }
      &::-webkit-slider-runnable-track {
        background: #fff;
      }
      &::-moz-range-track {
        background: #fff;
      }
    }
  }
  .zoom-control {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 1.5rem;
    width: 12rem;
    transform-origin: 6rem 5.9rem;
    transform: rotate(-90deg);
  }
  .paper {
    background: #fff;
    position: absolute;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.3);
  }
  .pdfPreviewBackground,
  .pdfPreview {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
  }
  .pdfPreviewBackground .mc-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50% -50%);
  }
}
.transition-for-element-editor-enter {
  //transform: translateX(10px);
  opacity: 0;
}
.transition-for-element-editor-leave-to {
  //transform: translateX(-10px);
  opacity: 0;
}

.buttons-for-designer {
  position: relative;
  height: 3.4em;
  flex-shrink: 0;
}
.buttons-for-designer > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.transition-for-buttons-leave-active,
.transition-for-buttons-enter-active {
  transition: all 0.2s ease;
}
.transition-for-buttons-enter {
  transform: translateY(-20px);
  opacity: 0;
}

.transition-for-buttons-leave-to {
  transform: translateY(20px);
  opacity: 0;
}
</style>
