<template>
  <div>
    <McButton
      @click="openCreateOrderForm"
      variant="primary"
      v-if="(merchantId && $perms.hasPermission('createOrders', merchantId)) || (!merchantId && $perms.hasMerchantsWithPermission('createOrders')) || $perms.isAdministrator()"
      icon="file-plus"
      :text="offline ? $t('offlineGiftcards.Create') : $t('orders.CreateManualOrder')"
      v-bind="$attrs"
    />
    <CreateOrderForm :offline="offline" id="createorderform" v-bind:key="merchantId" ref="createorderform" isModal @completed="$emit('orderCreated')" @orderCreated="showOrderCreatedData" />
    <b-modal id="orderCreatedData" :title="$t('orders.OrderCreated')">
      <div v-if="orderCreatedData">
        <p v-if="orderCreatedData.invoice.id">{{ $t('orders.OrderCreatedDescription') }}</p>
        <p v-else>{{ $tc('orders.OrderCreatedDescriptionNoInvoice', Object.keys(orderCreatedData.codes).length) }}</p>
        <p v-if="orderCreatedData.email">{{ $tc('orders.OrderCreatedSentByMail', Object.keys(orderCreatedData.codes).length + (orderCreatedData.invoice.id ? 1 : 0), { email: orderCreatedData.email }) }}</p>
      </div>
      <table v-if="orderCreatedData" class="ordercreatedtable">
        <tr>
          <td>{{ $t('orders.OrderIdColon') }}</td>
          <td>
            {{ orderCreatedData.orderId }}
          </td>
        </tr>
        <tr v-if="orderCreatedData.invoice.id">
          <td>{{ $t('orders.InvoiceIdColon') }}</td>
          <td>
            <a :href="orderCreatedData.invoice.link" target="_blank">{{ orderCreatedData.invoice.id }}</a>
          </td>
        </tr>
        <tr>
          <td>{{ $tc('orders.CertificateIdsColon', Object.keys(orderCreatedData.codes).length) }}</td>
          <td>
            <div v-for="v in orderCreatedData.codes" v-bind:key="v.code">
              <a :href="v.link" target="_blank">{{ v.code }}</a>
              <div v-if="v.deliveryLink">&nbsp;&rarr;&nbsp;<a :href="v.deliveryLink" target="_blank">{{ $t('general.DeliveryDesign') }}</a></div>
            </div>
          </td>
        </tr>
      </table>
      <!--pre>{{ orderCreatedData }}</pre-->
      <template slot="modal-footer">
        <McButton @click="openCreateOrderForm" :text="$t('orders.CreateOneMore')" variant="secondary" />
        <McButton @click="$bvModal.hide('orderCreatedData')" :text="$t('orders.Finish')" variant="primary" />
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      orderCreatedData: null,
    }
  },
  props: {
    merchantId: Number,
    offline: Boolean,
  },
  methods: {
    openCreateOrderForm() {
      this.$refs['createorderform'].forceReset()
      this.$bvModal.hide('orderCreatedData')
      this.$bvModal.show('createorderform')
    },
    showOrderCreatedData(data) {
      this.orderCreatedData = data
      this.$bvModal.show('orderCreatedData')
    },
  },
}
</script>
