import Vue from 'vue'

import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()

import {
  faEnvelope, //
  faUnlock,
  faSearch,
  faSpinner,
  faAt,
  faKey,
  faExclamationTriangle,
  faRadiation,
  faInfoCircle,
  faCheckCircle,
  faPaperPlane,
  faUserPlus,
  faFilePlus,
  faTrash,
  faSearchLocation,
  faPlus,
  faEdit,
  faUserEdit,
  faBolt,
  faPlug,
  faQuestion,
  faMapMarker,
  faHashtag,
  faPhone,
  faSimCard,
  faSignature,
  faUser,
  faBallotCheck,
  faTimes,
  faSearchMinus,
  faSearchPlus,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faCalendarCheck as fasCalendarCheck,
  faTimesCircle as fasTimesCircle,
  faBuilding as fasBuilding,
  faCity as fasCity,
  faSave as fasSave,
  faImage as fasImage,
  faSheep as fasSheep,
  faBan as fasBan,
  faBold as fasBold,
  faItalic as fasItalic,
  faStrikethrough as fasStrikethrough,
  faUnderline,
  faHeading,
  faListUl,
  faListOl,
  faQuoteRight,
  faHorizontalRule,
  faUndo,
  faRedo,
  faParagraph,
  faSignIn as fasSignIn,
  faPencilRuler as fasPencilRuler,
  faRuler as fasRuler,
  faFileImport as fasFileImport,
  faFileExport as fasFileExport,
  faDollarSign as fasDollarSign,
  faDatabase as fasDatabase,
  faFileCheck as fasFileCheck,
  faCog as fasCog,
} from '@fortawesome/pro-solid-svg-icons'

library.add(faEnvelope)
library.add(faUnlock)
library.add(faSearch)
library.add(faSpinner)
library.add(faAt)
library.add(faKey)
library.add(faExclamationTriangle)
library.add(faRadiation)
library.add(faInfoCircle)
library.add(faCheckCircle)
library.add(faPaperPlane)
library.add(faUserPlus)
library.add(faFilePlus)
library.add(faTrash)
library.add(faSearchLocation)
library.add(faPlus)
library.add(faEdit)
library.add(faUserEdit)
library.add(faBolt)
library.add(faPlug)
library.add(faQuestion)
library.add(faMapMarker)
library.add(faHashtag)
library.add(faPhone)
library.add(faSimCard)
library.add(faSignature)
library.add(faUser)
library.add(faBallotCheck)
library.add(faTimes)
library.add(faSearchMinus)
library.add(faSearchPlus)
library.add(fasArrowUp)
library.add(fasArrowDown)
library.add(fasChevronLeft)
library.add(fasChevronRight)
library.add(fasCalendarCheck)
library.add(fasTimesCircle)
library.add(fasBuilding)
library.add(fasCity)
library.add(fasSave)
library.add(fasImage)
library.add(fasSheep)
library.add(fasBan)
library.add(fasBold)
library.add(fasItalic)
library.add(fasStrikethrough)
library.add(faUnderline)
library.add(faHeading)
library.add(faListUl)
library.add(faListOl)
library.add(faQuoteRight)
library.add(faHorizontalRule)
library.add(faUndo)
library.add(faRedo)
library.add(faParagraph)
library.add(fasSignIn)
library.add(fasPencilRuler)
library.add(fasRuler)
library.add(fasFileImport)
library.add(fasFileExport)
library.add(fasDollarSign)
library.add(fasDatabase)
library.add(fasFileCheck)
library.add(fasCog)

import {
  faClock as farClock, //
  faCalendar as farCalendar,
  faTrashAlt as farTrashAlt,
  faTimesCircle as farTimesCircle,
  faCheckCircle as farCheckCircle,
  faThLarge as farThLarge,
  faThList as farThList,
  faHeading as farHeading,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faSearchDollar as farSearchDollar,
  faBold as farBold,
  faAlignLeft as farAlignLeft,
  faAlignRight as farAlignRight,
  faAlignCenter as farAlignCenter,
  faAlignJustify as farAlignJustify,
  faArrowLeft as farArrowLeft,
  faExpandArrowsAlt as farExpandArrowsAlt,
  faCompressArrowsAlt as farCompressArrowsAlt,
  faCropAlt as farCropAlt,
  faCheck as farCheck,
  faQrcode as farQrcode,
  faCode as farCode,
  faPrint as farPrint,
  faFileExcel as farFileExcel,
  faMoneyCheckEdit as farMoneyCheckEdit,
} from '@fortawesome/pro-regular-svg-icons'

library.add(farClock)
library.add(farCalendar)
library.add(farTrashAlt)
library.add(farTimesCircle)
library.add(farCheckCircle)
library.add(farThLarge)
library.add(farThList)
library.add(farHeading)
library.add(farEye)
library.add(farEyeSlash)
library.add(farSearchDollar)
library.add(farBold)
library.add(farAlignLeft)
library.add(farAlignRight)
library.add(farAlignCenter)
library.add(farAlignJustify)
library.add(farArrowLeft)
library.add(farExpandArrowsAlt)
library.add(farCompressArrowsAlt)
library.add(farCropAlt)
library.add(farCheck)
library.add(farQrcode)
library.add(farCode)
library.add(farPrint)
library.add(farFileExcel)
library.add(farMoneyCheckEdit)

import {
  faSignalAlt, //
  faSignalAlt1,
  faSignalAlt2,
  faSignalAlt3,
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faSnooze,
  faHistory,
  faLocation,
  faMapMarked,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faCars,
  faClipboardListCheck,
  faIdCard,
  faAlarmExclamation,
  faUsers,
  faCreditCardFront,
  faUserCrown,
  faUserChart,
  faUserCog,
  faBoxes,
  faWandMagic,
  faBrowser,
  faGlobeEurope,
  faFileSignature,
  faIdCardAlt,
  faFileInvoice,
  faFileCertificate,
  faUserTag,
  faBadgePercent,
  faFileContract,
  faCloudUpload,
  faCloudDownload,
  faFileInvoiceDollar,
  faUndo as fadUndo,
  faGreaterThanEqual,
  faMoneyBillWave,
  faCalendarExclamation,
  faFilePdf,
  faFolder,
  faFont,
  faTextSize,
  faEyeDropper,
  faShieldCheck,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(faBatteryEmpty)
library.add(faBatteryFull)
library.add(faBatteryHalf)
library.add(faBatteryQuarter)
library.add(faBatteryThreeQuarters)
library.add(faSignalAlt)
library.add(faSignalAlt1)
library.add(faSignalAlt2)
library.add(faSignalAlt3)
library.add(faSnooze)
library.add(faHistory)
library.add(faLocation)
library.add(faMapMarked)
library.add(faMapMarkedAlt)
library.add(faMapMarkerAlt)
library.add(faCars)
library.add(faClipboardListCheck)
library.add(faIdCard)
library.add(faAlarmExclamation)
library.add(faUsers)
library.add(faCreditCardFront)
library.add(faUserCrown)
library.add(faUserChart)
library.add(faUserCog)
library.add(faBoxes)
library.add(faWandMagic)
library.add(faBrowser)
library.add(faGlobeEurope)
library.add(faFileSignature)
library.add(faIdCardAlt)
library.add(faFileInvoice)
library.add(faFileCertificate)
library.add(faUserTag)
library.add(faBadgePercent)
library.add(faFileContract)
library.add(faCloudUpload)
library.add(faCloudDownload)
library.add(faFileInvoiceDollar)
library.add(fadUndo)
library.add(faGreaterThanEqual)
library.add(faMoneyBillWave)
library.add(faCalendarExclamation)
library.add(faFilePdf)
library.add(faFolder)
library.add(faFont)
library.add(faTextSize)
library.add(faEyeDropper)
library.add(faShieldCheck)

import {
  faBold as fatBold,
} from '@fortawesome/pro-thin-svg-icons'

library.add(fatBold)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

export default library
